import ApiHelper from './index'
import URL, { baseUrlOptions } from './baseUrl'

// get reports url for test portal based on selected environment.
const getReportsUrl = () => {
  var hostURL = localStorage.getItem('baseURL')
  if (hostURL == null) {
    localStorage.setItem('baseURL', URL.prodHostUrl)
    hostURL = localStorage.getItem('baseURL')
  }
  const selectedEnv = baseUrlOptions[hostURL]
  if (selectedEnv === 'PROD') return URL.prodReportsUrl
  else return URL.testReportsUrl
}

const reportUrl = import.meta.env.MODE === 'production' ? import.meta.env.VITE_REPORTS_URL : getReportsUrl()

const getErgonomicSnookPushingSheet = async (studyId) => {
  return await ApiHelper.post(`ergonomics/excelreport/${studyId}/SNOOK_Pushing`, {}, reportUrl)
}

const getErgonomicSnookPullingSheet = async (studyId) => {
  return await ApiHelper.post(`ergonomics/excelreport/${studyId}/SNOOK_Pulling`, {}, reportUrl)
}

const getErgonomicSnookCarryingSheet = async (studyId) => {
  return await ApiHelper.post(`ergonomics/excelreport/${studyId}/SNOOK_Carrying`, {}, reportUrl)
}

const getErgonomicSnookLiftingSheet = async (studyId) => {
  return await ApiHelper.post(`ergonomics/excelreport/${studyId}/SNOOK_Lifting`, {}, reportUrl)
}

const getErgonomicNioshSheet = async (studyId) => {
  return await ApiHelper.post(`ergonomics/excelreport/${studyId}/NIOSH`, {}, reportUrl)
}

const getErgonomicRebaSheet = async (studyId) => {
  return await ApiHelper.post(`ergonomics/excelreport/${studyId}/Reba_Intervals`, {}, reportUrl)
}

const getErgonomicRulaSheet = async (studyId) => {
  return await ApiHelper.post(`ergonomics/excelreport/${studyId}/Rula_Intervals`, {}, reportUrl)
}

const getErgonomicHandGripSheet = async (studyId) => {
  return await ApiHelper.post(`ergonomics/excelreport/${studyId}/Hand_Grip_Insertion_Force`, {}, reportUrl)
}

const getStationDesignSheet = async (studyId) => {
  return await ApiHelper.get(`station_improvement/station-design/excel_export/${studyId}`, {}, reportUrl)
}

const getWorkInstructionSheet = async (studyId) => {
  return await ApiHelper.get(`station_improvement/work-instruction/excel_export/${studyId}`, {}, reportUrl)
}

const getCycleTimeSheet = async (studyId) => {
  return await ApiHelper.get(`station_improvement/station-design/excel_export_cycle_time_measurement/${studyId}`, {}, reportUrl)
}

const getOperatorBalanceSheet = async (id, payload) => {
  return await ApiHelper.post(`line_balancing/line/${id}/export_excel/`, payload, reportUrl)
}

const getSWCTChartSheet = async (id, payload) => {
  return await ApiHelper.post(`line_balancing/line/${id}/export_excel/swct/`, payload, reportUrl)
}

const getCycleTimeDiagramSheet = async (id, payload) => {
  return await ApiHelper.post(`line_balancing/line/${id}/forvia_ctd_export_excel/`, payload, reportUrl)
}

const getLineBalanceWorkInstruction = async (id,payload) => {
  return await ApiHelper.post(`line_balancing/station/${id}/export_excel/work_instructions/`,payload,reportUrl)
}

export default {
  getErgonomicSnookPushingSheet,
  getErgonomicSnookPullingSheet,
  getErgonomicSnookCarryingSheet,
  getErgonomicSnookLiftingSheet,
  getErgonomicRulaSheet,
  getErgonomicHandGripSheet,
  getErgonomicNioshSheet,
  getStationDesignSheet,
  getErgonomicRebaSheet,
  getWorkInstructionSheet,
  getCycleTimeSheet,
  getOperatorBalanceSheet,
  getSWCTChartSheet,
  getCycleTimeDiagramSheet,
  getLineBalanceWorkInstruction
}
