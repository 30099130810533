export const nioshAssessmentResultData = [
  {
    key: 'load',
    text: 'Load Weight (lbs)',
    colorsRange: null
  },
  {
    key: 'HM',
    text: 'Horizontal Location (HM)',
    colorsRange: [0.40, 0.91]
  },
  {
    key: 'VM',
    text: 'Vertical Location (VM)',
    colorsRange: [0.70, 0.96]
  },
  {
    key: 'DM',
    text: 'Vertical Travel Distance (DM)',
    colorsRange: null
  },
  {
    key: 'AM',
    text: 'Asymmetry Angle (AM)',
    colorsRange: [0.57, 0.95]
  },
  {
    key: 'FM',
    text: 'Frequency (FM)',
    colorsRange: null,
  },
  {
    key: 'CM',
    text: 'Coupling (CM)',
    colorsRange: [0.90, 0.95]
  }
]

export const recommendedWeightLimitData = [
  {
    title: 'Lifting Index (LI)',
    children: [
      {
        key: 'LI',
        text: 'LI',
      },
      {
        key: 'FILI',
        text: 'FILI',
      }
    ],
    description: {
      '< 1.0': 'Safe, No action is required.',
      '1 - 3': 'Nominal risk, Investigate and implement change.',
      '> 3': 'High risk, Investigate and Implement change immediately.',
      'Hint': 'The goal is to design a job or task to be < 1.0'
    }
  },
  {
    title: 'Recommended Weight Limit (RWL)',
    children: [
      {
        key: 'RWL',
        text: 'RWL (lbs)',
      },
      {
        key: 'FIRWL',
        text: 'FIRWL (lbs)',
      }
    ]
  }
]

export const loadAnalysisData = {
  "weight_top": 61.6,
  "weight_mid": 10.3,
  "bend_without_weight": 0.2,
  "bend_with_weight": 19.7,
  "stand_without_weight": 7.1,
  "reach_top_without_weight": 1,
  'sitting': 0
}