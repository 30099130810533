import { defineStore } from 'pinia';
import { useStationStore } from './station'
import { isEmpty } from 'lodash-es';
import { useProductStore } from './cellProduct';
import OperatorTrackingService from 'src/services/operatorTracking';

export const useOperatorTrackingStore = defineStore('operatorTracking', {
    state: () => ({
        operatorIds: [],
        timeValues: {},
        regionTimeValues: {},
        operatorMoves: null,
        regionDistances: null,
        barSeriesData: {},
        stringImages: {},
        spaghettiImages: {},
        operatorSequence: {},
        operatorTrackingData: {},
        // loading
        trackingResultLoading: false,
        mergingResults: false,
        // merge tracklets
        operatorsTracklets: {}
    }),

    getters: {
        operatorIdsOptions() {
            const operators = [{ label: 'All Operators', value: 'all' }]
            const newValues = !this.operatorIds.length ? [] : this.operatorIds.map((id, index) => ({ label: `Operator ${index + 1}`, value: id }))
            return operators.concat(newValues)
        },
    },

    actions: {
        setTrackingLoading(loading) {
            this.trackingResultLoading = loading
        },

        resetTrackingData() {
            this.operatorIds = []
            this.timeValues = {}
            this.regionTimeValues = {}
            this.operatorMoves = null
            this.regionDistances = null
            this.barSeriesData = {}
            this.stringImages = {}
            this.spaghettiImages = {}
            this.operatorSequence = {}
            this.operatorTrackingData = {}
        },

        async startOperatorTracking(studyId) {
            return await OperatorTrackingService.startOperatorTracking(studyId)
        },

        //SD
        async getOperatorTrackingResults() {
            const { newStudyId: studyId } = useStationStore()
            const [error, data] = await OperatorTrackingService.getOperatorTrackingData(studyId)
            if (error) {
                console.log('error in getting operator tracking data')
                this.resetTrackingData()
                return
            }
            this.operatorTrackingData = data
            console.log('op results:', this.operatorTrackingData)
            this.getTimeValues()
            this.getMovementCountValues()
            this.getRegionDistancesValues()
            const { movement_count } = data
            this.operatorIds = movement_count && Object.keys(movement_count)?.length ? Object.keys(movement_count) : []
        },

        getTimeValues(selectedOperator = 'all') {
            const { regionsIdsToIndexMap } = useStationStore()
            const { combined_time_per_worker, time_per_worker } = this.operatorTrackingData || {}
            if (!combined_time_per_worker || !time_per_worker) return
            const operatorTimeValues = selectedOperator === 'all' ? combined_time_per_worker : time_per_worker[selectedOperator] || {}
            this.timeValues = Object.keys(regionsIdsToIndexMap).reduce((res, regionId) => {
                const index = regionsIdsToIndexMap[regionId]
                res[index] = operatorTimeValues[regionId] ? operatorTimeValues[regionId].toFixed(2) : 0
                return res
            }, {})
        },

        async getCellOperatorTrackingResults(studyId) {
            const [error, data] = await OperatorTrackingService.getCellOperatorTrackingResults(studyId)
            if (error) {
                console.log('error in getting operator tracking data')
                this.resetTrackingData()
                return
            }
            const { movement_count, spaghetti_url } = data || {}
            this.operatorIds = !isEmpty(spaghetti_url) ? Object.keys(spaghetti_url) : !isEmpty(movement_count) ? Object.keys(movement_count) : []
            this.operatorTrackingData = data
            this.stringImages = { ...data['string_url'], all: data['combined_string_url'] ?? '' }
            this.spaghettiImages = { ...data['spaghetti_url'], all: data['combined_spaghetti_url'] ?? '' }
            this.getOperatorTimeData()
            this.getRegionTimeValues()
            this.getMovementCountValues()
            this.getRegionDistancesValues()
            this.getOperatorSequence()
            this.getBarSeriesData()
        },

        getOperatorTimeData(selectedOperator = 'all') {
            const { time_per_worker, walk_time } = this.operatorTrackingData
            let obj = { workTime: 0, walkTime: 0, totalTime: 0 }
            if (isEmpty(time_per_worker) || isEmpty(walk_time)) return obj
            if (selectedOperator === 'all') {
                obj = {
                    workTime: Object.entries(time_per_worker).filter(([key]) => this.operatorIdsOptions.some((op) => op.value === key))?.reduce((sum, el) => sum += Object.values(el[1])?.reduce((workerTime, time) => workerTime += time), 0),
                    walkTime: Object.keys(walk_time).filter((key) => this.operatorIdsOptions.some((op) => op.value === key))?.reduce((sum, el) => sum += walk_time[el], 0)
                }
            } else {
                obj = {
                    workTime: !isEmpty(time_per_worker[selectedOperator]) ? Object.values(time_per_worker[selectedOperator]).reduce((sum, el) => sum += el, 0) : 0,
                    walkTime: walk_time[selectedOperator] ?? 0
                }
            }
            obj['totalTime'] = obj['walkTime'] + obj['workTime']
            this.timeValues = obj
            console.log('time values:', this.timeValues)
        },

        getRegionTimeValues(selectedOperator = 'all') {
            const { regionsIdsToIndexMap } = useProductStore()
            const { time_per_worker } = this.operatorTrackingData
            if (isEmpty(time_per_worker)) return
            const operatorTimeValues = selectedOperator === 'all' ?
                Object.entries(time_per_worker).filter(([key]) => this.operatorIdsOptions.some((op) => op.value === key)).reduce((res, [_, timeValues]) => {
                    for (let key in timeValues) {
                        if (res[key]) res[key] + timeValues[key]
                        else res[key] = timeValues[key]
                    }
                    return res
                }, {})
                : time_per_worker[selectedOperator] || {}

            this.regionTimeValues = Object.keys(regionsIdsToIndexMap).reduce((res, regionId) => {
                const index = regionsIdsToIndexMap[regionId]
                res[index] = operatorTimeValues[regionId] ? operatorTimeValues[regionId].toFixed(1) : 0
                return res
            }, {})
        },

        getMovementCountValues(selectedOperator = 'all') {
            const { combined_movement_count, movement_count } = this.operatorTrackingData
            const operatorMovements = selectedOperator === 'all' ? combined_movement_count : movement_count[selectedOperator]
            this.operatorMoves = operatorMovements && operatorMovements
        },

        getRegionDistancesValues(selectedOperator = 'all') {
            const { combined_movement_distances, movement_distances } = this.operatorTrackingData
            const regionDistances = selectedOperator === 'all' ? combined_movement_distances : movement_distances[selectedOperator]
            this.regionDistances = regionDistances && regionDistances
        },

        getOperatorSequence() {
            if (!this.operatorIdsOptions.length || isEmpty(this.operatorTrackingData.sequence)) return {}
            const allOperatorIds = this.operatorIdsOptions.slice(1)
            this.operatorSequence = allOperatorIds.reduce((sequence, { value }) => {
                sequence[value] = this.operatorTrackingData.sequence[value]
                return sequence
            }, {})
        },

        getBarSeriesData() {
            if (isEmpty(this.operatorTrackingData)) return []
            const { time_per_worker, walk_time } = this.operatorTrackingData
            if (!time_per_worker || !walk_time) return []
            const data = []
            const operatorWalkTimes = this.operatorIds.map((id) => [walk_time[id] ?? 0])
            const operatorWorkTime = this.operatorIds.map((id) => !isEmpty(time_per_worker[id]) ?
                [Object.values(time_per_worker[id]).reduce((sum, time) => sum += time, 0)]
                : [0])
            data[0] = {
                name: 'Walk',
                data: operatorWalkTimes.map((time) => parseFloat(time[0]?.toFixed(1)))
            }
            data[1] = {
                name: 'Work',
                data: operatorWorkTime.map((time) => parseFloat(time[0]?.toFixed(1)))
            }

            this.barSeriesData = data
        },

        // Merge
        async getOperatorTracklets() {
            const { cellStudyId: studyId } = useProductStore()
            const [error, data] = await OperatorTrackingService.getOperatorTracklets(studyId)
            if (error) {
                console.log('tracklets results error: ', error)
                return
            }
            console.log('tracklets:', data)
            this.operatorsTracklets = data.merge_url
        },

        async mergeOperatorResults(payload) {
            const { cellStudyId: studyId } = useProductStore()
            this.mergingResults = true
            const [error] = await OperatorTrackingService.mergeOperatorTracklets(studyId, payload)
            if (error) {
                this.mergingResults = false
                console.log('error:', error)
                return 0
            }
            await this.getOperatorTracklets(studyId)
            this.mergingResults = false
        }
    }
})
