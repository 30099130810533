import ApiHelper from './index'

const getSummarizedSuggestions = async (studyId, queryParams) => {
  return await ApiHelper.get(
    `deep_model/get_process_improvement_suggestion/${studyId}`,
    queryParams
  )
}

const autoDetectCycles  =async (studyId, fileId, payload) => {
  return await ApiHelper.post(`/deep_model/cycle_detection/${studyId}/${fileId}`,payload)
}

export default {
  autoDetectCycles,
  getSummarizedSuggestions
}
