<template>
  <a-modal
    :open="showMarkCycleModal"
    :title="null"
    :footer="null"
    :closable="false"
    width="100%"
    destroy-on-close
    wrap-class-name="full-modal"
    :bodyStyle="{ display: 'flex', padding: 0, flexDirection: 'column' }"
  >
    <mark-cycles
      :selectedFileId="this.videoFiles[this.currVideo].id"
      @close="handleMarkCycleClose"
    />
  </a-modal>

  <!-- Upload video File Manager -->
  <a-modal
    v-model:open="showFiles"
    title="Add Videos From File Manager"
    :ok-text="'Upload'"
    destroy-on-close
    @cancel="handleCloseFileModal"
  >
    <template #footer>
      <a-space class="d-flex align-items-center justify-content-end">
        <a-button
          :loading="isUploading"
          :disabled="!formState.selectedFiles.length"
          type="primary"
          @click="handleUpload"
          >Add</a-button
        >
      </a-space>
    </template>

    <a-form layout="vertical" :model="formState" style="min-height: 350px">
      <a-form-item label="Select Video">
        <VideoSelect
          :selectedFiles="formState.selectedFiles"
          @changeFile="(val) => (formState.selectedFiles = val)"
          :fileOptions="fileOptions"
          :oldFiles="newStudy.files.map((file) => file.id)"
        />
      </a-form-item>
    </a-form>
  </a-modal>

  <div class="center" v-if="loading && !isDemoOngoing">
    <a-spin size="large" />
  </div>

  <div v-else class="cycles-list-wrapper">
    <a-row v-if="showMasterCycle" class="w-100" :gutter="[24, 32]">
      <a-col span="24" class="text-center position-relative">
        <a-button
          class="position-absolute start-0 d-flex align-items-center"
          @click="showMasterCycle = false"
        >
          Back
          <template #icon>
            <arrow-left-outlined />
          </template>
        </a-button>
        <a-typography-text class="fs-6">
          Select the master cycle as reference for step definition.
        </a-typography-text>
      </a-col>

      <a-col span="8" class="cycle-video" v-for="cycle in workCycles" :key="cycle.id">
        <div class="cycles-video-wrapper">
          <segment-video
            v-if="studyFilesObject[cycle?.file]?.url"
            :videoUrl="studyFilesObject[cycle.file].url"
            :durationTime="getCycleDuration(cycle)"
            :framePerSec="studyFilesObject[cycle.file].fps"
            :segment-play="{
              name: cycle.name,
              start: cycle.segment_start,
              end: cycle.segment_end
            }"
          />
          <a-flex justify="space-between" align="center" class="video-content border-top p-2">
            <a-typography-title :level="5" ellipsis>{{ cycle.name }}</a-typography-title>
            <a-space>
              <a-button
                size="small"
                :type="[cycle.id === masterCycle ? 'primary' : 'default']"
                :loading="cycle.id === masterCycle && updatingMaster"
                :disable="masterCycle || updatingMaster"
                @click="setMasterCycle(cycle.id)"
                >Select Master</a-button
              >
              <a-button
                danger
                size="small"
                :loading="deleteCycleId === cycle.id"
                :disabled="deleteCycleId || updatingMaster"
                @click="handleDeleteCycle(cycle.id)"
                ><template #icon> <DeleteOutlined /> </template
              ></a-button>
            </a-space>
          </a-flex>
        </div>
      </a-col>
    </a-row>

    <div v-else id="upload-container" class="preview-video-wrapper">
      <strong> {{ videoFiles[currVideo]?.name }}</strong>

      <div class="media-container">
        <video
          id="sd-adjust-video-container"
          ref="uploadedVideo"
          class="preview-video"
          controls
          muted
          controlslist="nodownload"
          disablePictureInPicture
          :src="currentVideoUrl"
          @loadedmetadata="loadedMetaData"
          crossorigin="anonymous"
        ></video>

        <div class="mt-2 w-100 position-relative" v-if="videoFiles?.length">
          <span class="d-flex justify-content-center align-items-center">
            <span class="fs-6">Video File :&nbsp;</span>
            <LeftOutlined
              class="file-nav-icon"
              :class="{ disabled: currVideo <= 0 }"
              @click="handleVideoChange(-1)"
            />
            <span class="lh-1 fs-5"> {{ currVideo + 1 }} / {{ videoFiles?.length }} </span>
            <RightOutlined
              :class="{ disabled: currVideo === videoFiles?.length - 1 }"
              class="file-nav-icon"
              @click="handleVideoChange(1)"
            />
          </span>

          <a-popconfirm
            placement="left"
            title="Do you want to delete this video?"
            ok-text="Yes"
            cancel-text="No"
            class="position-absolute end-0 translate-middle"
            @confirm="handleRemoveVideo(currVideo)"
          >
            <DeleteOutlined class="fs-6 position-absolute top-50" style="color: red" />
          </a-popconfirm>
        </div>
      </div>

      <a-space v-if="!loading && !isDemoOngoing" class="d-flex justify-content-start">
        <a-button v-if="videoFiles.length" :disabled="isStudyInProgress" @click="handleOpenFiles"
          >Add File</a-button
        >
        <a-button v-if="workCycles.length" @click="handleShowCyclesPreview"
          >Select Master Cycle</a-button
        >
        <a-button
          id="station-design-mark-cycle-button"
          v-if="videoFiles"
          :disabled="isStudyInProgress"
          type="primary"
          @click="handleClickMarkCycle"
        >
          Mark Cycles
        </a-button>
      </a-space>

      <a-space v-if="loading && isDemoOngoing" class="d-flex justify-content-start">
        <a-button>Select Master Cycle</a-button>
        <a-button id="station-design-mark-cycle-button"> Mark Cycles </a-button>
      </a-space>

      <a-typography-text>
        Add one or more cycle(s) from the video(s) by pressing Mark Cycles.
      </a-typography-text>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useUserStore } from 'src/stores/user.js'
import { useStationStore } from 'src/stores/station'
import { useSegmentationStore } from 'src/stores/segmentation'
import { ArrowLeftOutlined } from '@ant-design/icons-vue'
import { useFileStore } from 'src/stores/file'
import { useToast } from 'vue-toastification'
import { LeftOutlined, RightOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import eventBus from 'src/utils/eventBus'
import SegmentVideo from './SegmentVideo.vue'
import MarkCycles from './MarkCycles.vue'
import VideoSelect from 'src/components/Shared/VideoSelect.vue'

export default {
  components: {
    SegmentVideo,
    VideoSelect,
    MarkCycles,
    ArrowLeftOutlined,
    LeftOutlined,
    RightOutlined,
    DeleteOutlined
  },
  inject: ['nextTab', 'showError', 'showInfo'],
  setup() {
    return {
      toast: useToast()
    }
  },
  data() {
    return {
      uploadedVideoIds: new Set(),
      isUploading: false,
      loading: true,
      showFiles: false,
      masterCycle: null,
      showMarkCycleModal: false,
      showMasterCycle: false,
      updatingMaster: false,
      deleteCycleId: null,
      formState: {
        selectedFiles: []
      },
      isVideoLoaded: false,
      currVideo: 0,
      videoFiles: [],
      currentVideoUrl: ''
    }
  },

  computed: {
    ...mapState(useStationStore, [
      'newStudy',
      'newStudyId',
      'workCycles',
      'studyVideoUrl',
      'studyFilesObject',
      'hasAllVideosHaveCycle',
      'stationDesignDummyObject',
      'selectedMasterCycleId',
      'masterCycleVideoId',
      'isStudyInProgress'
    ]),

    ...mapState(useFileStore, ['fileOptions']),
    ...mapState(useUserStore, ['isDemoOngoing']),
    ...mapState(useSegmentationStore, ['cyclesNotSegmented']),
    previewVideoUrl() {
      return this.isDemoOngoing ? this.stationDesignDummyObject.url : this.studyVideoUrl
    }
  },

  watch: {
    currVideo(val) {
      if (val < 0) return
      this.setCurrentVideoUrl()
    }
  },

  methods: {
    ...mapActions(useStationStore, [
      'updateStudy',
      'fetchStudy',
      'fetchWorkCyles',
      'updateWorkCycle',
      'deleteWorkCycle',
      'updateWorkCycleInList',
      'updateStation',
      'getStudyFilesObject',
      'setStudyFilesObject',
      'setMaterCycleVideoUrl'
    ]),
    ...mapActions(useFileStore, ['fetchFileOptions', 'fetchPresignedUrl']),

    setCurrentVideoUrl() {
      const fileId = this.videoFiles[this.currVideo]?.id
      this.currentVideoUrl = this.studyFilesObject?.[fileId]?.url
    },

    setFilesData() {
      const files = this.newStudy.files
      if (!files?.length) return
      this.videoFiles = [...files]
      this.setCurrentVideoUrl()
      this.uploadedVideoIds = files.map((file) => file.id)
    },

    handleVideoChange(count = 0) {
      this.isVideoLoaded = false
      this.currVideo = this.currVideo + count
    },

    async handleRemoveVideo(stepVideoIndex) {
      const obj = this.videoFiles[stepVideoIndex]

      if (this.isStudyInProgress) {
        this.showInfo('The video can not deleted while processing.')
        return
      }

      if (this.videoFiles.length === 1) {
        this.showInfo('The study must have atleast one video!')
        return
      }

      if (obj.id == this.masterCycleVideoId) {
        this.showInfo('Can not delete Master cycle video.')
        return
      }

      // if on last vidoe and delete -> decrement the count
      if (this.currVideo === this.videoFiles.length - 1) {
        this.currVideo = this.currVideo - 1
      }

      this.uploadedVideoIds = this.uploadedVideoIds.filter((videoId) => videoId !== obj.id)
      const deletedVideoCycles = this.workCycles.filter((cycle) => cycle.file === obj.id)
      const unSegmentedVideos = this.cyclesNotSegmented.notSegmentedVideos
      const payload = { files: this.uploadedVideoIds }
      // if delete video which is not segmented yet and progress: 1
      if (
        unSegmentedVideos?.includes(obj.id) &&
        deletedVideoCycles.length &&
        this.newStudy.progress
      ) {
        payload['progress'] = 0
      }
      await this.updateStudy(payload)
      await this.fetchWorkCyles()
      const tempDict = this.studyFilesObject
      delete tempDict[obj.id]
      this.setStudyFilesObject(tempDict)
      this.setFilesData()
    },

    loadedMetaData() {
      if (!this.$refs.uploadedVideo) return
      this.$refs.uploadedVideo.currentTime = 0
      this.isVideoLoaded = true
    },

    handleStopVideo() {
      this.$refs.uploadedVideo?.pause()
      this.$refs.uploadedVideo.currentTime = 0
    },

    // uploading from file manager
    handleOpenFiles() {
      this.$refs.uploadedVideo?.pause()
      this.showFiles = true
    },

    async handleUpload() {
      this.isUploading = true
      await this.updateStudy({ files: [...this.uploadedVideoIds, ...this.formState.selectedFiles] })
      await this.getStudyFilesObject()
      this.setFilesData()
      this.isUploading = false
      this.currVideo = 0
      this.handleCloseFileModal()
    },

    handleCloseFileModal() {
      this.formState = {
        selectedFiles: []
      }
      this.showFiles = false
    },

    // Mark cycle
    handleClickMarkCycle() {
      if (!this.isVideoLoaded) {
        this.toast.info('Please wait for the video to load.')
        return
      }
      this.handleStopVideo()
      this.showMarkCycleModal = true
    },

    async handleMarkCycleClose(isUpdatedCycles) {
      this.showMarkCycleModal = false
      if (isUpdatedCycles) {
        this.loading = true
        await this.fetchWorkCyles()
        if (this.workCycles.length) this.showMasterCycle = true
        this.loading = false
      } else this.fetchWorkCyles()
    },

    // Cycles
    handleShowCyclesPreview() {
      this.handleStopVideo()
      this.showMasterCycle = true
    },

    getCycleDuration(cycle) {
      const fileId = cycle.file
      const fps = fileId ? this.studyFilesObject[fileId]?.fps : 30
      const startFrame = cycle.segment_start * fps
      const endFrame = cycle.segment_end * fps
      return (endFrame - startFrame) / fps
    },

    async setMasterCycle(cycleId) {
      if (this.isStudyInProgress || this.newStudy.isCompleted) {
        this.showInfo('Master Cycle cannot be updated once the study has been completed.')
        return
      }
      this.masterCycle = cycleId
      const oldMasterCycleId = this.selectedMasterCycleId
      if (oldMasterCycleId && oldMasterCycleId === this.masterCycle) return
      const masterCycleFile = this.workCycles.find((c) => c.id === cycleId)?.file
      const thumbnail = this.fileOptions.find((opt) => opt.value == masterCycleFile)?.thumbnail

      this.updatingMaster = true
      if (oldMasterCycleId) {
        await this.updateWorkCycle(oldMasterCycleId, { is_master: false })
      }
      await this.updateWorkCycle(this.masterCycle, { is_master: true })
      if (thumbnail) await this.updateStudy({ thumbnail_path: thumbnail })
      const temp = [...this.workCycles]
      const updatedCycles = temp.map((c) =>
        c.id === oldMasterCycleId
          ? { ...c, is_master: false }
          : c.id === this.masterCycle
          ? { ...c, is_master: true }
          : c
      )
      this.updateWorkCycleInList(updatedCycles)
      this.setMaterCycleVideoUrl()
      this.updatingMaster = false
    },

    async handleDeleteCycle(cycleId) {
      if (this.isStudyInProgress) {
        this.showInfo('The video can not deleted while processing.')
        return
      }

      if (cycleId === this.selectedMasterCycleId) {
        this.showInfo('Can not delete Master Cycle')
        return
      }
      this.deleteCycleId = cycleId
      await this.deleteWorkCycle(cycleId)
      await this.fetchWorkCyles()
      this.deleteCycleId = null
      if (!this.workCycles.length) this.showMasterCycle = false
    },

    async validateMasterCycleSelection() {
      if (
        this.newStudy?.station?.no_of_steps &&
        !this.cyclesNotSegmented?.notSegmentedCycles?.length &&
        this.newStudy?.progress
      ) {
        await this.updateStudy({ progress: 0 })
      }

      if (!this.workCycles?.length || !this.workCycles?.some((c) => c.is_master === true)) {
        this.toast.info('Please select master cycle!')
        return
      } else if (!this.hasAllVideosHaveCycle) {
        this.toast.info('All video(s) should have atleast one cycle marked!')
      } else {
        this.nextTab()
      }
    }
  },

  async created() {
    if (this.isDemoOngoing) return
    eventBus.$on('cycles-next-page', this.validateMasterCycleSelection)
  },

  async beforeMount() {
    if (!this.fileOptions?.length) this.fetchFileOptions()
    // await this.fetchWorkCyles(this.newStudy.id)
    this.setFilesData()
    this.loading = false
  },

  mounted() {
    if (!this.workCycles.length) return
    this.masterCycle = this.workCycles.find((c) => c.is_master === true)?.id
  },

  beforeUnmount() {
    eventBus.$off('cycles-next-page', this.validateMasterCycleSelection)
  }
}
</script>

<style scoped>
.cycles-list-wrapper,
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  padding: 2em 4em;
  width: 100%;
}

.preview-video-wrapper {
  max-width: 640px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: start;
  gap: 1.4em;
  width: 100%;
}

.cycles-video-container {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2em;
}

.cycles-video-container > div {
  flex-basis: 30%;
}

/* .media-container {
  width: 440px;
} */

.media-container > * {
  width: 100%;
}

.preview-video {
  height: calc(450px * 0.8);
  width: calc(640px * 0.8);
  object-fit: fill;
  border-radius: 5px;
  border: 1px solid lightgray;
}

.file-nav-icon.disabled {
  color: #828282;
  pointer-events: none;
}

.cycles-video-wrapper {
  border: 1px solid lightgray;
  border-radius: 14px;
}

:deep(.cycles-video-wrapper video) {
  outline: none;
  height: 300px;
  object-fit: fill;
  border-radius: 14px 14px 0px 0px;
  border: none;
}

:deep(.cycles-video-wrapper .timeline-wrapper) {
  padding: 0 6px 10px 6px;
}

/* Media query for screens greater than 1600px */
@media screen and (min-width: 1700px) {
  .preview-video {
    padding: 10px;
    height: 450px;
    width: 640px;
  }
}
</style>
