<template>
  <div class="main-container">
    <a-modal v-model:open="isCommentModalVisible" centered title="Comment" :footer="null">
      <a-textarea v-model:value="comment" :rows="4" placeholder="Enter your comment here" />
      <a-button type="primary" class="mt-2" :loading="updatingCycle" @click="addComments"
        >Add Comment</a-button
      >
    </a-modal>

    <a-modal v-model:open="showRenameModal" title="Rename Cycle" centered :footer="null">
      <a-input v-model:value="cycleName" @keyup.enter="handleRenameCycle">
        <template #suffix>
          <a-spin size="small" v-if="updatingCycle" />
          <a-tooltip v-else title="Rename">
            <EnterOutlined class="cursor-pointer" @click="handleRenameCycle" />
          </a-tooltip>
        </template>
      </a-input>
    </a-modal>

    <a-modal
      id="cycle-time-delete-confirm-modal"
      centered
      width="35vw"
      :open="deleteCycleId ? true : false"
      title="Confirmation"
    >
      <p>This will delete all data of this cycle, Are you sure you want to delete?</p>
      <template #footer>
        <a-space class="d-flex w-100">
          <a-button @click="deleteCycleId = null"> No </a-button>
          <a-button danger :loading="deleteCycleLoading" @click="onRemoveCycle()"> Yes </a-button>
        </a-space>
      </template>
    </a-modal>

    <a-row class="w-100">
      <a-col span="6">
        <a-list bordered :data-source="sortedWorkCycles" item-layout="vertical" class="cycles-list">
          <template #header>
            <span class="list-header py-3">Cycles</span>
          </template>
          <template #renderItem="{ item, index }">
            <a-list-item
              class="cycle-item m-2"
              :class="{
                selected: selectedCycle === item.id,
                disabled: updatingSteps || applyingToAll
              }"
            >
              <a-list-item-meta class="w-100" @click="handleClickCycle(index, item)">
                <template #avatar>
                  <img :src="item.thumbnail" style="height: 80px; width: 80px" />
                </template>

                <template #title>
                  <a-typography-text class="w-100"> #{{ index + 1 }} </a-typography-text>
                  <div v-html="getCycleMeta(item)"></div>
                </template>
              </a-list-item-meta>
              <template #actions>
                <a-space size="middle" class="w-100 d-flex justify-content-end">
                  <a-tooltip title="Rename">
                    <EditOutlined
                      :class="{ 'active-cycle-actions': selectedCycle === item.id }"
                      class="cycle-actions fs-5"
                      @click="openRenameModal(item.name)"
                    />
                  </a-tooltip>
                  <a-tooltip title="Add Comment">
                    <CommentOutlined
                      :class="{ 'active-cycle-actions': selectedCycle === item.id }"
                      class="cycle-actions fs-5"
                      @click="showComments(item)"
                    />
                  </a-tooltip>
                  <a-tooltip title="Delete">
                    <DeleteOutlined
                      v-if="!item?.is_master"
                      :class="{
                        'active-cycle-actions': selectedCycle === item.id,
                        'text-danger': selectedCycle === item.id
                      }"
                      class="cycle-actions fs-5"
                      @click="showDeleteModal(item)"
                    />
                  </a-tooltip>
                </a-space>
              </template>
            </a-list-item>
          </template>
        </a-list>
      </a-col>

      <a-col span="18" class="d-flex flex-column py-2" style="gap: 6px">
        <a-space class="d-flex justify-content-between mb-2">
          <a-typography-title :level="4" class="mb-0">Edit Step Timings</a-typography-title>
          <a-typography-text
            >Please right click the relevant step name at the bottom</a-typography-text
          >
          <a-space class="d-flex align-items-center">
            <a-button
              class="d-flex align-items-center"
              :disabled="!isStepTimeUpdate || updatingSteps || applyingToAll"
              @click="handleReset"
            >
              <template #icon>
                <UndoOutlined />
              </template>
              Reset Step Timings
            </a-button>
            <a-button
              v-if="isMaster && sortedWorkCycles.length > 1"
              class="d-flex align-items-center"
              :disabled="!isStepTimeUpdate || updatingSteps || applyingToAll"
              :loading="applyingToAll"
              @click="handleApplyToAll"
            >
              Apply to All
            </a-button>
            <a-button
              :disabled="isApplyDisable || applyingToAll || updatingSteps"
              class="d-flex align-items-center"
              :loading="updatingSteps"
              @click="handleApply"
            >
              Apply
            </a-button>
            <a-button
              class="d-flex align-items-center"
              :disabled="applyingToAll || updatingSteps"
              @click="handleCloseModal"
            >
              <template #icon>
                <ShrinkOutlined />
              </template>
              Exit
            </a-button>
          </a-space>
        </a-space>

        <div class="cycle-video-container">
          <video
            class="cycle-video-player"
            ref="video"
            muted
            crossorigin="anonymous"
            disablePictureInPicture
            @playing="isPlaying = true"
            @loadedmetadata="getCycleVideoTime()"
            @timeupdate="handleGetCurrentTime"
          >
            <source :src="videoFileUrl" :key="videoFileUrl" />
          </video>
        </div>

        <div class="timeline-container mt-2" ref="timelineContainer">
          <div class="d-flex justify-content-between align-items-center">
            <span class="position-relative">
              <small class="me-1">{{ getFormattedTime(currentTime) }}</small>
              <small v-if="isStepMarking" class="stepSelectedText">
                Start Selected, Adjust Slider and Press SPACE to mark step end
              </small>
              <small v-if="editStep" class="stepSelectedText">
                Step Selected, Adjust Slider and Press SPACE to complete editing
              </small>
            </span>
            <div class="play-button-container position-relative">
              <small>
                <PauseOutlined style="font-size: 20px" v-if="isPlaying" @click="togglePlaying" />
                <CaretRightOutlined style="font-size: 20px" v-else @click="togglePlaying" />
              </small>
            </div>
            <small> Playback speed: </small>
            <div class="position-relative">
              <a-select
                v-model:value="playbackRate"
                class="me-2 d-flex align-items-center p-1 pt-1"
                size="small"
                :options="playbackRatesOptions"
                @change="handlePlaybackRateChange"
              />
            </div>
            <small>{{ getFormattedTime(endCycleTime) }} </small>
          </div>

          <a-slider
            v-model:value="currentFrame"
            :min="0"
            :max="totalFrames"
            :tipFormatter="(val) => formatFrame(val)"
            id="annotate-slider"
            class="mx-0 mt-1"
            @change="handleVideoPause"
          />

          <div
            :style="{ height: '20px', width: '100%', position: 'relative' }"
            class="m-0 border d-flex"
          >
            <!-- vertical bar to represent current time -->
            <div class="hairline" :style="cursorStyle">
              <div class="hairline-top"></div>
            </div>

            <painter
              v-for="(interval, index) in cycleSteps"
              :key="index"
              :isMarking="isStepMarking || editStep"
              :contextMenuItems="getContextMenuItems(interval)"
              :interval="interval"
              :intervalIndex="index"
              :current-slider-percent="currentSliderPercent"
              :show-border="true"
              :prevEnd="getPrevEnd(index)"
              @editInterval="handleEditStep"
              @removeInterval="handleRemoveStep"
              @addNewInterval="handleDivideAtTimestamp"
            >
              <template #intervalName>
                <a-tooltip v-if="interval">
                  <template #title>
                    <span>
                      {{ interval.name }}
                      ({{ getSegmentTime(interval.segment_start) }} -
                      {{ getSegmentTime(interval.segment_end) }} )
                    </span>
                  </template>
                  {{ interval.name }}
                </a-tooltip>
              </template>
            </painter>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  UndoOutlined,
  PauseOutlined,
  ShrinkOutlined,
  CaretRightOutlined,
  DeleteOutlined,
  CommentOutlined,
  EditOutlined,
  EnterOutlined
} from '@ant-design/icons-vue'
import { mapActions, mapState } from 'pinia'
import { colors, cycleStepsMenuItem, masterCycleStepsMenuItem } from '../config'
import { getFormattedTime } from 'src/utils/outline'
import { useStationStore } from 'src/stores/station'
import { useClassificationStore } from 'src/stores/classification'
import { useHandTrackingStore } from 'src/stores/handTracking'
import { useSegmentMapingStore } from 'src/stores/segmentMapping'
import { useSegmentationStore } from 'src/stores/segmentation'
import { getPostureIndexName } from 'src/utils/ergonomics'
import { useToast } from 'vue-toastification'
import { isEmpty } from 'lodash-es'
import { toLower } from 'src/utils/helpers'
import Painter from './Painter.vue'

const toast = useToast()

export default {
  props: ['videoUrl'],
  emits: ['close'],
  components: {
    Painter,
    UndoOutlined,
    PauseOutlined,
    ShrinkOutlined,
    CaretRightOutlined,
    DeleteOutlined,
    CommentOutlined,
    EditOutlined,
    EnterOutlined
  },
  setup: () => ({
    colors,
    getFormattedTime
  }),

  data() {
    return {
      sortedWorkCycles: [],
      selectedCycle: null,
      playbackRate: 1,
      stepIndex: null,
      isStepMarking: false,
      isPlaying: false,
      currentTime: 0,
      prevFrames: null,
      initCycleTime: null,
      endCycleTime: null,
      cycleDuration: null,
      videoPlayInterval: null,
      applyingToAll: false,
      updatingSteps: false,
      stepIdToObjMap: {},
      isMerged: false,
      isVideoLoaded: false,
      deleteCycleId: null,
      deleteCycleLoading: false,
      deleteStepIds: [],
      updatingCycle: false,
      cycleName: null,
      showRenameModal: false,
      toastCount: 0,
      // Slider
      currentFrame: 0,
      currentSliderPercent: 0,
      startingTime: null,
      editStep: false,
      editPosition: null,
      videoFileUrl: '',
      //
      cycleSteps: [],
      isCommentModalVisible: false,
      comment: '',
      masterIndexToNameMap: {}
    }
  },

  computed: {
    ...mapState(useSegmentationStore, ['segmentationData', 'allSegments']),
    ...mapState(useStationStore, [
      'isCell',
      'newStudy',
      'workCycles',
      'masterCycle',
      'studyFilesObject',
      'masterCycleVideoId',
      'cyclesIdToObjMap'
    ]),

    playbackRatesOptions() {
      return [1, 2, 4, 8, 16].map((rate) => ({
        value: rate,
        label: `${rate}x`
      }))
    },

    segmentsForEditTiming() {
      if (!this.segmentationData.length || !this.selectedCycle) return []
      const segments = this.segmentationData
        .filter((segment) => segment.work_cycle.id === this.selectedCycle)
        ?.sort((a, b) => b.work_cycle.is_master - a.work_cycle.is_master)
      return segments
    },

    selectedCycleObject() {
      if (!this.selectedCycle) return
      return this.workCycles.find((cycle) => cycle.id === this.selectedCycle)
    },

    videoFPS() {
      if (isEmpty(this.selectedCycleObject) || isEmpty(this.studyFilesObject)) return
      const fps = this.studyFilesObject[this.selectedCycleObject.file]?.fps
      return fps || 30
    },

    cursorStyle() {
      return { left: this.currentSliderPercent + '%' }
    },

    isSegmentChanged() {
      return JSON.stringify(this.cycleSteps) !== JSON.stringify(Object.values(this.stepIdToObjMap))
    },

    isApplyDisable() {
      const isStepLengthChanges = this.cycleSteps.length !== this.segmentsForEditTiming.length
      if (this.isMaster) {
        return this.sortedWorkCycles.length == 1 && isStepLengthChanges ? false : isStepLengthChanges ? true : !this.isStepTimeUpdate
      }
      return isStepLengthChanges || !this.isStepTimeUpdate
    },

    isStepTimeUpdate() {
      if (!this.segmentsForEditTiming.length) return false
      return (
        this.cycleSteps.length !== Object.values(this.segmentsForEditTiming).length ||
        this.cycleSteps.some((step) => {
          if (!step.id) return true
          const prev = this.stepIdToObjMap[step.id]
          return step.segment_start !== prev.segment_start || step.segment_end !== prev.segment_end
        })
      )
    },

    isMaster() {
      if (isEmpty(this.selectedCycleObject)) return false
      return this.selectedCycleObject?.is_master === true
    },

    totalFrames() {
      if (!this.segmentsForEditTiming?.length) return 0
      const segments = this.segmentsForEditTiming
      const first = segments[0]
      const end = segments.at(-1)
      const diff = end.segment_end - first.segment_start
      return diff
    }
  },

  watch: {
    playbackRate(newRate) {
      this.handlePlaybackRateChange()
    },

    currentFrame(frame) {
      if (this.isPlaying) this.currentSliderPercent = this.getPercent(frame)
      else this.updateVideoTime()
      this.editStep ? this.editStepPaint() : this.handlePainterChange(frame)
    },

    totalFrames() {
      // this.setExistingStepIntervals()
    }
  },

  methods: {
    ...mapActions(useSegmentationStore, ['createSegments', 'updateSegment', 'fetchAllSteps']),
    ...mapActions(useHandTrackingStore, ['generateHandTrackingResult']),
    ...mapActions(useClassificationStore, ['assignTherbligFromStepName']),
    ...mapActions(useSegmentMapingStore, ['startSegmentRemaping', 'startSegmentationMapping']),
    ...mapActions(useStationStore, [
      'updateStudy',
      'fetchStudy',
      'fetchWorkCyles',
      'updateWorkCycle',
      'deleteWorkCycle',
      'updateStation',
      'updateWorkCycleInList'
    ]),

    getContextMenuItems(interval) {
      return interval?.work_cycle?.is_master ? masterCycleStepsMenuItem : cycleStepsMenuItem
    },

    showComments(item) {
      if (item.id !== this.selectedCycle) return
      const comment = this.selectedCycleObject?.comments
      this.comment = comment ? comment : ''
      this.isCommentModalVisible = true
    },

    async addComments() {
      const payload = { comments: this.comment }
      this.updatingCycle = true
      const res = await this.updateWorkCycle(this.selectedCycle, payload)
      this.updatingCycle = false
      if (!res) {
        toast.error('Error occurred in updating Comment!')
        return
      }
      const updatedCycles = this.workCycles.map((cycle) =>
        cycle.id === this.selectedCycle ? { ...cycle, ...payload } : cycle
      )
      this.updateSortedCycles(this.selectedCycle, payload)
      this.updateWorkCycleInList(updatedCycles)
      toast.success(`Comment is updated successfully!`)
      this.isCommentModalVisible = false
    },

    updateSortedCycles(id, obj) {
      const temp = [...this.sortedWorkCycles]
      const index = temp.findIndex((c) => c.id === id)
      if (index < 0) return
      temp[index] = { ...temp[index], ...obj }
      this.sortedWorkCycles = temp
    },

    showDeleteModal(item) {
      if (item.id !== this.selectedCycle) return
      this.deleteCycleId = item.id
    },

    getIntervalDisplay(interval) {
      if (interval.name) {
        return interval.name
      } else if (!this.isMaster) {
        return this.masterIndexToNameMap[interval.step_index]
      } else {
        return `Step ${interval.step_index + 1}`
      }
    },

    getCycleMeta(cycle) {
      const time = this.getTimeDuration(
        cycle.cycle_start_frame_no,
        cycle.cycle_end_frame_no,
        cycle.file?.fps || 30
      )
      const no_of_steps = this.getStepCount(cycle.id)
      const cycleName = cycle.is_master ? `${cycle.name} (Master)` : cycle.name
      return `Name: ${cycleName}<br>Time: ${time}<br>Steps: ${no_of_steps}`
    },

    getTimeDuration(startFrame, endFrame, fps = 30) {
      const totalSeconds = (endFrame - startFrame) / fps
      const time = getFormattedTime(totalSeconds)
      return time
    },

    getStepCount(cycleId) {
      return this.segmentationData.filter((step) => step.work_cycle.id === cycleId).length
    },

    handleClickCycle(index, item) {
      this.selectedCycle = item.id
      this.editStep = false
      this.isStepMarking = false
      this.cycleSteps = []
      this.isVideoLoaded = false
      const videoFile = this.studyFilesObject[item.file]
      if (videoFile && videoFile.url) {
        this.videoFileUrl = videoFile.url
        this.$refs.video.load()
      }
    },

    handlePlaybackRateChange() {
      if (this.$refs.video) {
        this.$refs.video.playbackRate = this.playbackRate
      }
    },

    formatFrame(frame) {
      return `Frame id: ${frame}`
    },

    getFrame(percent) {
      if (percent === undefined) return 0
      return Math.ceil((percent * this.totalFrames) / 100)
    },

    getPercent(frame) {
      return (frame / this.totalFrames) * 100
    },

    isOverlapped(currentStart) {
      return this.cycleSteps.some(
        ({ segment_start: start, segment_end: end }) => start <= currentStart && currentStart <= end
      )
    },

    getPrevEnd(index) {
      if (index - 1 < 0) return this.cycleSteps[index].segment_start
      return this.cycleSteps[index - 1]?.segment_end
    },

    handleGetCurrentTime(event) {
      this.currentTime = event.target.currentTime
      this.currentFrame = this.currentTime * this.videoFPS - this.prevFrames
    },

    getSegmentTime(stepPercent) {
      const timeInSec = (this.prevFrames + this.getFrame(stepPercent)) / this.videoFPS
      return getFormattedTime(timeInSec)
    },

    setMasterStepIndexToNameMap() {
      if (!this.cycleSteps.length) return
      this.masterIndexToNameMap = this.cycleSteps.reduce((map, el) => {
        map[el.step_index] = el.name
        return map
      }, {})
    },

    setStepIdToObjectMap() {
      if (!this.cycleSteps?.length) return
      this.stepIdToObjMap = this.cycleSteps.reduce((res, el) => {
        res[el.id] = { ...el }
        return res
      }, {})
    },

    updateVideoTime() {
      const video = this.$refs.video
      if (video) {
        this.currentTime = (this.prevFrames + this.currentFrame) / this.videoFPS
        video.currentTime = this.currentTime
        this.currentSliderPercent = this.getPercent(this.currentFrame)
      }
    },

    getCycleVideoTime() {
      if (!this.segmentsForEditTiming?.length) return
      const segments = this.segmentsForEditTiming
      const first = segments[0]
      const end = segments.at(-1)
      this.initCycleTime = first.segment_start / this.videoFPS
      this.endCycleTime = end.segment_end / this.videoFPS
      this.prevFrames = first.segment_start > 0 ? first.segment_start - 1 : 0
      this.cycleDuration = Math.floor(end.segment_end - first.segment_start) / this.videoFPS
      this.currentTime = this.initCycleTime
      if (this.$refs.video) {
        this.$refs.video.currentTime = this.currentTime
        this.$refs.video?.pause()
      }
      this.setExistingStepIntervals()
      this.isPlaying = false
      this.isVideoLoaded = true
    },

    setExistingStepIntervals() {
      let defaultIntervals = []
      const segments = this.segmentsForEditTiming
      if (!this.totalFrames) return // for getting percent value

      segments.forEach((segment, index) => {
        const { segment_end, segment_start } = segment
        const newStart =
          segment_start === segments[index - 1]?.segment_end ? segment_start + 1 : segment_start
        // const newEnd = segment_end === segment_start ? segment_end + 1 : segment_end
        const newEnd = segment_end
        defaultIntervals.push({
          ...segment,
          step_start: segment_start,
          step_end: segment_end,
          segment_start: this.getPercent(newStart - this.prevFrames),
          segment_end: this.getPercent(newEnd - this.prevFrames),
          name: this.getIntervalDisplay(segment),
          parentStep: segment.name
        })
      })
      this.cycleSteps = defaultIntervals.sort((a, b) => a.segment_start - b.segment_start)
      if (this.isMaster) this.setMasterStepIndexToNameMap()
      this.setStepIdToObjectMap()
    },

    async togglePlaying() {
      if (!this.isVideoLoaded || !this.cycleDuration) return
      if (this.isPlaying && !this.$refs.video?.paused) {
        this.handleVideoPause()
      } else if (!this.isPlaying) {
        await this.$refs.video?.play()
        this.videoPlayInterval = setInterval(this.videoTimeUpdate, 100 / this.videoFPS)
      }
    },

    handleVideoPause() {
      if (!this.$refs.video || this.$refs.video?.paused) return
      else this.$refs.video?.pause()
      this.isPlaying = false
      clearInterval(this.videoPlayInterval)
    },

    videoTimeUpdate() {
      if (this.$refs.video && this.currentTime >= this.endCycleTime) {
        if (!this.$refs.video?.paused) this.$refs.video?.pause()
        this.isPlaying = false
        this.$refs.video.currentTime = this.initCycleTime
        clearInterval(this.videoPlayInterval)
      }
    },

    // Slider /  Step Marking
    handleKeyDownEvents(evt) {
      if (this.isCommentModalVisible || !this.isVideoLoaded || this.showRenameModal) return
      if (evt.code === 'Space') {
        evt.preventDefault()
        if (this.editStep) {
          // if (!this.validStepDuration()) return
          this.resetEditStep()
        } else if (this.isStepMarking) {
          this.handleMarkStepEnd()
        } else if (this.isMaster && !this.isStepMarking) {
          this.handleVideoPause()
          this.handleMarkStepStart()
        }
      }
    },

    setStepIndexToMark(currentSliderPercent) {
      const steps = this.cycleSteps.sort((a, b) => a.segment_start - b.segment_start)
      for (let idx = 0; idx < steps.length; idx++) {
        const { segment_start } = steps[idx]
        if (currentSliderPercent < segment_start) {
          this.stepIndex = idx
          return
        }
      }
      this.stepIndex = this.cycleSteps.length
    },

    getStepsData(neighbour = 'next') {
      const temp = [...this.cycleSteps]
      const index = this.stepIndex
      const currentStep = temp[index]
      let neighbourStep = null
      if (neighbour === 'next') neighbourStep = temp[index + 1]
      else if (neighbour === 'previous') neighbourStep = temp[index - 1]

      return {
        temp,
        index,
        currentStep,
        neighbourStep
      }
    },

    validStepDuration(multiple = false) {
      const intv = [...this.cycleSteps]
      if (multiple) {
        let isValid = false
        isValid = intv.some((c) => {
          const start = this.getFrame(c.segment_start)
          const end = this.getFrame(c.segment_end)
          return end - start > 0 && end - start <= this.videoFPS
        })
        return isValid
      }
      const start = this.getFrame(intv[this.stepIndex].segment_start)
      const end = this.getFrame(intv[this.stepIndex].segment_end)
      if (end - start > 0 && end - start <= this.videoFPS) {
        if (!this.toastCount) toast.info('Step interval should not be less than 1 second!')
        this.toastCount++
        return false
      }
      this.toastCount = 0
      return true
    },

    updateStepsIndexesInList(stepIndex) {
      const temp = [...this.cycleSteps]
      for (let i = stepIndex; i < temp.length; i++) {
        temp[i] = {
          ...temp[i],
          step_index: i
        }
      }
      this.cycleSteps = temp
    },

    handleMarkStepStart() {
      this.startingTime = this.currentFrame
      const currentSliderPercent = this.getPercent(this.currentFrame)
      if (this.isOverlapped(currentSliderPercent)) {
        if (!this.toastCount) toast.info('Steps can not overlapped!')
        this.toastCount++
        return
      }
      this.toastCount = 0
      this.isStepMarking = true
      this.setStepIndexToMark(currentSliderPercent)

      const obj = {
        id: '',
        step_index: this.stepIndex,
        segment_start: currentSliderPercent,
        segment_end: currentSliderPercent,
        name: `Step ${this.cycleSteps.length + 1}`,
        parentStep: ''
      }
      let temp = [...this.cycleSteps, obj].sort((a, b) => a.segment_start - b.segment_start)
      this.cycleSteps = temp
    },

    handlePainterChange(currentSliderValue) {
      if (!this.isStepMarking) return
      if (currentSliderValue <= this.startingTime && this.cycleSteps.length > 0) return
      const end = this.getPercent(currentSliderValue)
      const temp = [...this.cycleSteps]
      const nextStepStart = temp[this.stepIndex + 1]?.segment_start
      if (nextStepStart && end >= nextStepStart) return
      temp[this.stepIndex].segment_end = end
      this.cycleSteps = temp
    },

    handleMarkStepEnd() {
      if (!this.isStepMarking) return
      // check overlapped when end marking
      const intv = [...this.cycleSteps]
      const start = this.getFrame(intv[this.stepIndex].segment_start)
      const end = this.getFrame(intv[this.stepIndex].segment_end)
      if (end === start) this.cycleSteps.splice(this.stepIndex, 1)

      // if (end - start > 0 && end - start <= this.videoFPS) {
      //   if (!this.toastCount) toast.info('Step interval should not be less than 1 second!')
      //   this.toastCount++
      //   return
      // }
      this.toastCount = 0
      this.updateStepsIndexesInList(this.stepIndex)
      this.stepIndex = null
      this.startingTime = null
      this.isStepMarking = false
    },

    handleRemoveStep(stepIndex) {
      if (!this.isMaster) {
        const temp = [...this.cycleSteps]
        const stepId = temp[stepIndex]?.id
        if (stepId) this.deleteStepIds.push(stepId)
      }
      this.cycleSteps.splice(stepIndex, 1)
      this.updateStepsIndexesInList(stepIndex)
      this.stepIndex = null
      this.startingTime = null
      this.isStepMarking = false
      this.editStep = false
      this.editPosition = null
      if (!this.cycleSteps.length) {
        this.currentFrame = 0
      }
    },

    handleEditStep(editValue, editStepIndex, position) {
      if (this.editStep) this.resetEditStep()
      this.editStep = true
      this.stepIndex = editStepIndex
      this.currentFrame = this.getFrame(editValue)
      this.editPosition = position
      this.handleVideoPause()
    },

    resetEditStep() {
      this.handleVideoPause()
      this.editStep = false
      this.stepIndex = null
      this.editPosition = null
    },

    editStepPaint() {
      if (this.stepIndex < 0) return
      this.editPosition === 'start'
        ? this.editStepStart()
        : this.editPosition === 'end' && this.editStepEnd()
    },

    editStepStart() {
      const { temp, currentStep, neighbourStep: prevStep } = this.getStepsData('previous')
      const newStart = this.getPercent(this.currentFrame)
      // return if start is greater then end
      if (currentStep && currentStep.segment_end < newStart) return
      // return if start is merged with the previous label start
      if ((prevStep && newStart <= prevStep.segment_end) || !currentStep) {
        if (!this.isMerged) toast.info('Two steps can not merge!')
        this.isMerged = true
        return
      }
      this.isMerged = false
      temp[this.stepIndex].segment_start = newStart
      this.cycleSteps = temp
    },

    editStepEnd() {
      const { temp, currentStep, neighbourStep: nextStep } = this.getStepsData('next')
      const newEnd = this.getPercent(this.currentFrame)
      // return if end is greater then start
      if (currentStep && currentStep.segment_start > newEnd) return
      // return if end is merged with the next label start
      if ((nextStep && newEnd >= nextStep.segment_start) || !currentStep) {
        if (!this.isMerged) toast.info('Two steps can not merge!')
        this.isMerged = true
        return
      }
      this.isMerged = false
      temp[this.stepIndex].segment_end = newEnd
      this.cycleSteps = temp
    },

    handleDivideAtTimestamp() {
      const temp = [...this.cycleSteps]

      const stepIndex = temp.findIndex((step) => {
        const { segment_start, segment_end } = step
        return segment_start < this.currentSliderPercent && this.currentSliderPercent < segment_end
      })
      if (stepIndex < 0) return
      const currentSliderPercent = this.getPercent(this.currentFrame)
      const firstStep = temp[stepIndex]

      const start = this.getFrame(firstStep.segment_start)
      const curr = this.getFrame(currentSliderPercent)
      const end = this.getFrame(firstStep.segment_end)

      if (end - curr <= this.videoFPS || curr - start <= this.videoFPS) {
        return toast.info('Step interval should not be less than 1 second!')
      }

      const newStart = this.getFrame(currentSliderPercent) + 1
      const prevChild = temp.filter((step) => step.parentStep === firstStep.parentStep)
      const newStep = {
        parentStep: firstStep.parentStep,
        step_index: stepIndex + 1,
        segment_start: this.getPercent(newStart),
        segment_end: firstStep.segment_end,
        name: `${firstStep.parentStep} ${getPostureIndexName(prevChild.length - 1)}`
      }

      temp[stepIndex] = {
        ...firstStep,
        segment_start: firstStep.segment_start,
        segment_end: currentSliderPercent
      }

      for (let i = stepIndex + 1; i < temp.length; i++) {
        temp[i] = {
          ...temp[i],
          step_index: temp[i].step_index < stepIndex ? temp[i].step_index : temp[i].step_index + 1,
          name: temp[i]?.name || `Step ${temp[i].step_index + 1}`
        }
      }

      temp.splice(stepIndex + 1, 0, newStep)
      this.cycleSteps = temp
    },

    getUpdatedStepPayload() {
      const { cycle_start_frame_no, cycle_end_frame_no } = this.selectedCycleObject
      let updatedSteps = []
      this.cycleSteps.forEach((step, index, arr) => {
        const { step_index, segment_start, segment_end, name } = step
        let newStart = this.prevFrames + this.getFrame(segment_start)
        let newEnd = this.prevFrames + this.getFrame(segment_end)
        const prevEnd = updatedSteps?.at(-1) ? updatedSteps.at(-1).segment_end : null
        const nextStart = arr[index + 1]
          ? this.prevFrames + this.getFrame(arr[index + 1].segment_start)
          : null
        newStart = prevEnd && newStart <= prevEnd ? prevEnd + 1 : newStart
        newEnd = nextStart && newEnd >= nextStart ? nextStart - 1 : newEnd
        updatedSteps.push({
          id: step?.id,
          step_index: this.isMaster ? step_index : this.stepIdToObjMap[step.id]?.step_index,
          name,
          segment_start: Math.max(Number(cycle_start_frame_no), newStart),
          segment_end: Math.min(Number(cycle_end_frame_no) - 1, newEnd)
        })
      })
      // append deleted steps with 0 zero time
      if (!this.isMaster) {
        this.deleteStepIds.forEach((stepId) => {
          const obj = this.stepIdToObjMap[stepId]
          updatedSteps.push({
            id: stepId,
            step_index: obj.step_index,
            name: obj.name,
            segment_start: 0,
            segment_end: 0
          })
        })
        // append old removed steps
        const oldRemovedStep = this.allSegments.filter(
          (s) =>
            s.work_cycle.id === this.selectedCycle && s.segment_end === 0 && s.segment_start === 0
        )
        oldRemovedStep.forEach((step) => {
          updatedSteps.push({
            id: step.id,
            step_index: step.step_index,
            name: step.name,
            segment_start: 0,
            segment_end: 0
          })
        })
      }
      return updatedSteps.sort((a, b) => a.step_index - b.step_index)
    },

    async handleApplyToAll() {
      const updatedSteps = this.getUpdatedStepPayload()
      console.log('apply all: ', updatedSteps)
      try {
        this.applyingToAll = true
        const res = await this.createSegments(this.selectedCycle, updatedSteps)
        if (!res) throw new Error()
        toast.info(
          "Applying your changes to all the cycles. This may take a few minutes. Please don't close this tab.",
          { timeout: 3000 }
        )
        if (
          !this.isCell &&
          this.newStudy.get_improvement &&
          this.newStudy?.station?.no_of_work_regions >= 1
        ) {
          await this.generateHandTrackingResult()
        }
        await this.startSegmentRemaping()
        await this.updateStudy({ no_of_steps: updatedSteps.length })
        await this.fetchAllSteps()
      } catch (error) {
        toast.error('Error occurred in applying the changes!')
        this.handleReset()
        console.log('error is update step timing:', error)
      } finally {
        this.deleteStepIds = []
        this.applyingToAll = false
        this.setMasterStepIndexToNameMap()
      }
    },

    async handleApply() {
      const updatedSteps = this.getUpdatedStepPayload()
      console.log('save only: ', updatedSteps)
      try {
        this.updatingSteps = true
        const res = await this.createSegments(this.selectedCycle, updatedSteps)
        if (!res) throw new Error()
        await this.fetchAllSteps()
      } catch (error) {
        toast.error('Error occurred in applying the changes!')
        this.handleReset()
        console.log('error is update step timing:', error)
      } finally {
        this.deleteStepIds = []
        this.updatingSteps = false
      }
    },

    handleCloseModal() {
      this.fetchAllSteps()
      this.$emit('close')
    },

    async onRemoveCycle() {
      this.deleteCycleLoading = true
      const temp = [...this.sortedWorkCycles]
      const cycleIndex = temp.findIndex((c) => c.id === this.deleteCycleId)
      const nextCycleIndex = cycleIndex + 1 === temp.length ? cycleIndex - 1 : cycleIndex + 1
      const nextCycle = temp[nextCycleIndex]
      this.selectedCycle = nextCycle?.id
      this.sortedWorkCycles.splice(cycleIndex, 1)
      const videoFile = this.studyFilesObject[nextCycle.file]
      if (videoFile && videoFile.url) {
        this.videoFileUrl = videoFile.url
        this.$refs.video.load()
      }
      await this.deleteWorkCycle(this.deleteCycleId)
      await this.fetchAllSteps()
      this.deleteCycleId = null
      this.deleteCycleLoading = false
    },

    openRenameModal(cycleName) {
      this.cycleName = cycleName
      this.showRenameModal = true
    },

    async handleRenameCycle() {
      const temp = [...this.sortedWorkCycles]
      const cycleId = this.selectedCycle
      const index = temp.findIndex((c) => c.id === cycleId)
      if (index < 0 || !this.cycleName) return
      const newCycleName = toLower(this.cycleName)
      if (this.sortedWorkCycles.some((c) => toLower(c.name) === newCycleName)) {
        return toast.info('Cycle names should be unique across all video files!')
      }
      this.updatingCycle = true
      const payload = { name: this.cycleName }
      const res = await this.updateWorkCycle(cycleId, payload)
      this.updatingCycle = false
      if (!res) {
        toast.error('Error occurred in renaming the cycle!')
        return
      }
      const updatedCycles = this.workCycles.map((cycle) =>
        cycle.id === cycleId ? { ...cycle, ...payload } : cycle
      )
      this.updateSortedCycles(cycleId, payload)
      this.updateWorkCycleInList(updatedCycles)
      toast.success('Cycle name update successfully!')
      this.cycleName = ''
      this.showRenameModal = false
    },

    computeTimelineWidth() {
      if (this.$refs.timeline) {
        this.timelineWidth = parseInt(this.$refs.timeline.getBoundingClientRect().width, 10)
      }
    },

    handleReset() {
      this.currentTime = 0
      this.isStepMarking = false
      this.editStep = false
      this.deleteStepIds = []
      this.getCycleVideoTime()
    }
  },

  created() {
    window.addEventListener('resize', this.computeTimelineWidth)
  },

  mounted() {
    if (this.workCycles?.length > 0) {
      this.sortedWorkCycles = this.workCycles?.slice().sort((a, b) => b.is_master - a.is_master)
      this.selectedCycle = this.sortedWorkCycles[0]?.id
      this.videoFileUrl = this.studyFilesObject?.[this.selectedCycleObject.file]?.url
    }

    this.handleReset()
    this.cycleDuration = null
    this.computeTimelineWidth()
    document.addEventListener('keydown', this.handleKeyDownEvents)
    this.handlePlaybackRateChange()
  },

  beforeUnmount() {
    // this.$refs.video.currentTime = 0
    this.stepIdToObjMap = {}
    if (this.videoPlayInterval) clearInterval(this.videoPlayInterval)
    if (!this.$refs.video?.paused) this.$refs.video?.pause()
    window.removeEventListener('resize', this.computeTimelineWidth)
    document.removeEventListener('keydown', this.handleKeyDownEvents)
  }
}
</script>
<style scoped>
.cycles-list {
  height: 95vh;
  display: flex;
  flex-direction: column;
}

.cycles-list > :deep(.ant-list-header) {
  background: #fafafa !important;
  font-weight: 600 !important;
  padding: 10px !important;
  font-size: 14px;
  color: #0b072d;
  text-align: left;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.cycles-list > :deep(.ant-spin-nested-loading) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cycles-list > :deep(.ant-spin-nested-loading > .ant-spin-container) {
  flex-grow: 1;
  height: 1px;
  overflow-y: auto;
}

.cycle-item :deep(.ant-list-item-action li) {
  width: 100%;
}

.play-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.main-container {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.cycles-head-container {
  display: flex;
  justify-content: space-between;
}

.cycle-video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.cycle-video-player {
  position: absolute;
  height: 100%;
  border-radius: 5px;
  border: 1px solid lightgray;
}

.control-container {
  display: flex;
  padding: 0 0.7em;
}

.timeline-container {
  height: 90px;
  border: 1px solid lightgray;
  padding: 0 1em;
  position: relative;
  overflow: hidden;
}

.button-container {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.hairline {
  height: 100%;
  width: 1px;
  background: black;
  position: relative;
  z-index: 1;
  transition: left 0ms ease-out;
}

.hairline-top {
  width: 10px;
  height: 10px;
  position: absolute;
  transform: translate(-45%, 0%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid black;
}

.timeline {
  height: 3px;
  width: 100%;
  background: lightgray;
  position: relative;
}

.step-label {
  font-size: small;
  position: absolute;
  left: 0%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: inherit;
  cursor: default;
  z-index: 9;
}

.step-timeline {
  color: #000;
  height: 20px;
}

.cycle-item {
  cursor: pointer;
  background: #f8f9fa;
  border-radius: 5px;
}

.cycle-item.selected {
  background-color: #dcf2fc;
  color: #fff;
}

.cycle-item.disabled {
  background-color: lightgray !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: none;
}

.cycle-item :deep(.ant-list-item-meta) {
  margin: 0;
}

.stepSelectedText {
  color: #dc3545;
  margin: 0;
  position: absolute;
  left: 100%;
  white-space: nowrap;
  margin-left: 10px;
  top: 10%;
}

:deep(.cycle-actions.anticon) {
  color: rgba(33, 37, 41, 0.75);
  pointer-events: none;
}

:deep(.active-cycle-actions.anticon) {
  color: #0d6efd;
  pointer-events: all;
}
</style>
<style>
.edit-timings-modal > .ant-modal-content {
  padding: 6px 12px !important;
}
</style>
