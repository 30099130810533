import ExcelService from 'src/services/excel'

export function getDownloadExcelApiSD(key) {
  switch (key) {
    case 'workContent':
      return ExcelService.getStationDesignSheet;
    case 'workInstruction':
      return ExcelService.getWorkInstructionSheet;
    case 'cycleTime':
      return ExcelService.getCycleTimeSheet;
    default:
      return null;
  }
}


export function getDownloadExcelApiLB(key) {
  switch (key) {
    case 'operatorBalance':
      return ExcelService.getOperatorBalanceSheet;
    case 'swctChart':
      return ExcelService.getSWCTChartSheet;
    case 'cycleTimeDiagram':
      return ExcelService.getCycleTimeDiagramSheet;
    case 'workInstruction':
      return ExcelService.getLineBalanceWorkInstruction;
    default:
      return null;
  }
}
