export const nioshResultTableColumn = [
  {
    title: '',
    dataIndex: 'input_name',
    align: 'left',
    width: '50%'
  },
  {
    title: 'Origin',
    dataIndex: 'origin',
    align: 'center'
  },
  {
    title: 'Destination',
    dataIndex: 'dest',
    align: 'center'
  }
]

export const snookResultTabs = [
  {
    key: 'assessment_result',
    tab: 'Assessment Results',
  },
  {
    key: 'load_analysis',
    tab: 'Load Analysis',
  },
]

export const snookResultTableColumn = [
  {
    title: '',
    dataIndex: 'label',
    align: 'left',
    width: '50%'
  },
  {
    title: '',
    dataIndex: 'value',
    align: 'left',
    width: '50%'
  },
]

export const snookResultRecommColumns = [
  {
    title: 'Results',
    children: [
      {
        label: 'Risk Index',
        key: 'risk_index',
        first_value_key: 'initial_risk_index',
        second_value_key: 'sustained_risk_index',
        description: {
          '< 1.0': 'Safe, No action is required.',
          '1 - 3': 'Nominal risk, Investigate and implement change.',
          '> 3': 'High risk, Investigate and Implement change immediately.',
          'Hint': 'The goal is to design a job or task to be < 1.0'
        }
      },
      {
        label: 'Design Goal (lbs)',
        key: 'design_goal',
        first_value_key: 'initial_design_goal',
        second_value_key: 'sustained_design_goal'
      },
    ],
  },
  // {
  //   label: '@gender Population (%)',
  //   key: 'gender_population',
  //   first_value_key: 'acceptable_pushing_initial_percentage',
  //   second_value_key: 'acceptable_pushing_sustained_percentage'
  // },
]

export const snookGenderKeys = {
  carrying: 'acceptable_carrying_percentage',
  lifting: 'acceptable_lifting_percentage',
  pushing: ['acceptable_pushing_initial_percentage', 'acceptable_pushing_sustained_percentage'],
  pulling: ['acceptable_pulling_initial_percentage', 'acceptable_pulling_sustained_percentage']
}



// {
//   title: 'Sustained Force',
//   dataIndex: 'sustained_force',
//   key: 'sustained_force'
// }

// children: [
//   {
//     title: 'Risk Index',
//     dataIndex: 'risk_index',
//     key: 'risk_index'
//   },
//   {
//     title: 'Male Population (%)',
//     dataIndex: 'male_population',
//     key: 'male_population'
//   },
//   {
//     title: 'Female Population (%)',
//     dataIndex: 'female_population',
//     key: 'female_population'
//   }
// ],
// children: [
//   {
//     title: 'Design Goal',
//     dataIndex: 'design_goal',
//     key: 'design_goal'
//   },
// ]

export const loadClassesColumns = [
  {
    title: 'Activity',
    dataIndex: 'weight_class',
    key: 'weight_class',
    width: '50%',
  },
  {
    title: 'Percent of Job',
    dataIndex: 'job_percent',
    key: 'job_percent',
    align: 'center',
  },
  {
    title: 'Frequency',
    dataIndex: 'frequency',
    key: 'frequency',
  },
];

export const loadClassesLabelsMap = {
  weight_top: { name: 'High Lift', description: '(Lifting load above torso)' },
  weight_mid: { name: 'Power Zone', description: '(Lifting load at torso level)' },
  bend_with_weight: { name: 'Stooper Lift', description: '(Bending while holding load)' },
  bend_without_weight: { name: 'Free Motion', description: '(Bending without load)' },
  stand_without_weight: { name: 'Standing Static Stance', description: '(Standing / Walking without load)' },
  reach_top_without_weight: { name: 'Overhead Reach ', description: '(Reach above torso without load)' },
  sitting: { name: 'Sitting Static Stance', description: '(Sitting without load)' },
};

export const loadClassesColorsMap = {
  weight_top: '#c25656A1',
  weight_mid: '#d88d2aA1',
  bend_with_weight: '#e2c84cA1',
  bend_without_weight: '#8fb7b3A1',
  stand_without_weight: '#75a152A1',
  reach_top_without_weight: '#5f79a7A1',
  sitting: '#75a152A1',
};

