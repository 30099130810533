function keepDefaultView(to, from) {
  if (from.matched.length) {
    to.matched.at(-1).components.default = from.matched.at(-1).components.default
  }
}

export default [
  {
    path: '/user',
    name: 'User Layout',
    component: () => import('src/components/Core/AppLayout.vue'),
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('src/components/User/Home/Index.vue')
      },
      {
        path: 'files',
        name: 'File Management',
        component: () => import('src/components/User/FileManagement/Index.vue'),
        children: [
          {
            path: '',
            name: 'Files List',
            component: () => import('src/components/User/FileManagement/FilesList.vue'),
            meta: { title: 'Files List', parent: 'File Management' }
          },
          {
            path: 'edit/:fileId',
            name: 'Edit File',
            component: () => import('src/components/User/FileManagement/VideoEditing/VideoEditing.vue'),
            meta: { title: 'Video Editing', parent: 'File Management' }
          }
        ]
      },
      {
        path: 'file-manager',
        name: 'File Manager',
        component: () => import('src/components/User/FileManagement/Index.vue'),
        children: [
          {
            path: ':id?',
            name: 'Files Manager Home',
            component: () => import('src/components/User/FileManager/Home.vue'),
            meta: { title: 'Files Manager Home', parent: 'File Manager' }
          },
          {
            path: 'edit/:fileId',
            name: 'Edit Video File',
            component: () => import('src/components/User/FileManager/VideoEditing/VideoEditing.vue'),
            meta: { title: 'Video Editing', parent: 'File Manager' }
          }
        ]
      },
      {
        path: 'org-users',
        name: 'Users',
        component: () => import('src/components/User/Users/Index.vue')
      },
      {
        path: 'user-manual',
        name: 'User Manual',
        component: () => import('src/components/User/UserManual/Index.vue')
      },
      {
        path: 'app-fundamental',
        name: 'App Fundamental',
        component: () => import('src/components/User/AppFundamental/Index.vue')
      },
      {
        path: 'station-design',
        name: 'Station Design',
        component: () => import('src/components/User/StationBalancing/Index.vue'),
        children: [
          {
            path: 'studies',
            name: 'studies',
            component: () => import('src/components/User/StationBalancing/StudyList.vue'),
            meta: { title: 'Station Design', parent: 'Station Design' }
          },
          {
            path: 'study/:id',
            name: 'new-study',
            component: () => import('src/components/User/StationBalancing/NewStudy.vue'),
            children: [
              {
                path: 'cycles',
                name: 'cycles',
                component: () =>
                  import('src/components/User/StationBalancing/components/DefineCycles.vue'),
                meta: {
                  index: 0,
                  text: 'Done with Cycles',
                  icon: 'ArrowRightOutlined',
                  next: 'divide'
                }
              },
              {
                path: 'divide',
                name: 'divide',
                component: () =>
                  import('src/components/User/StationBalancing/components/DivideSegments.vue'),
                meta: {
                  index: 1,
                  text: 'Divide and proceed',
                  icon: 'ArrowRightOutlined',
                  previous: 'cycles',
                  next: 'steps'
                }
              },
              {
                path: 'steps',
                name: 'steps',
                component: () =>
                  import('src/components/User/StationBalancing/components/DefineSteps.vue'),
                meta: {
                  index: 2,
                  text: 'Done adjusting',
                  icon: 'ArrowRightOutlined',
                  previous: 'divide',
                  next: 'timeStudy'
                }
              },
              {
                path: 'time-study',
                name: 'timeStudy',
                component: () =>
                  import(
                    'src/components/User/StationBalancing/components/TimeMotionStudyResult.vue'
                  ),
                meta: {
                  index: 3,
                  text: '',
                  icon: 'ArrowRightOutlined',
                  previous: 'steps',
                  next: 'outline'
                }
              },
              {
                path: 'outline',
                name: 'outline',
                component: () =>
                  import('src/components/User/StationBalancing/components/MultipleRegions.vue'),
                meta: {
                  index: 4,
                  text: 'Done with outlining',
                  icon: 'ArrowRightOutlined',
                  previous: 'timeStudy',
                  next: 'improvements'
                }
              },
              {
                path: 'improvements',
                name: 'improvements',
                component: () =>
                  import(
                    'src/components/User/StationBalancing/components/ImprovementQuestions.vue'
                  ),
                meta: {
                  index: 5,
                  text: 'Done with Improvements',
                  icon: 'ArrowRightOutlined',
                  previous: 'outline',
                  next: 'audit'
                }
              },
              {
                path: 'audit',
                name: 'audit',
                component: () =>
                  import('src/components/User/StationBalancing/components/WorkstationAudit.vue'),
                meta: {
                  index: 6,
                  text: 'Done auditing',
                  icon: 'ArrowRightOutlined',
                  previous: 'improvements',
                  next: 'suggestions'
                }
              },
              {
                path: 'suggestions',
                name: 'suggestions',
                component: () =>
                  import('src/components/User/StationBalancing/components/ViewResult.vue'),
                meta: {
                  index: 7,
                  text: 'Done with Suggestions',
                  icon: 'ArrowRightOutlined',
                  previous: 'audit',
                  next: 'notes'
                }
              },
              {
                path: 'notes',
                name: 'notes',
                component: () =>
                  import('src/components/User/StationBalancing/components/KaizenNotes.vue'),
                meta: {
                  index: 8,
                  text: 'Done',
                  icon: 'ArrowRightOutlined',
                  previous: 'suggestions'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'floor-layout',
        name: 'Floor Layout',
        component: () => import('src/components/User/CellOptimization/Index.vue'),
        children: [
          {
            path: 'studies',
            name: 'cellStudies',
            component: () => import('src/components/User/CellOptimization/CellStudyList.vue'),
            meta: { title: 'Cell Studies', parent: 'Floor Layout' }
          },
          {
            path: 'study/:id',
            name: 'cell-new-study',
            components: {
              'default': () => import('src/components/User/CellOptimization/CellStudyList.vue'),
              'modal': () => import('src/components/User/CellOptimization/CellNewStudy.vue')
            },
            children: [
              {
                path: 'video',
                name: 'cell-video',
                components: {
                  'modal': () => import('src/components/User/CellOptimization/components/CellVideo.vue'),
                },
                meta: {
                  index: 0,
                  text: 'Done with Video Trimming',
                  icon: 'ArrowRightOutlined',
                  next: 'cell-outline'
                },
              },
              {
                path: 'outline',
                name: 'cell-outline',
                component: () =>
                  import('src/components/User/CellOptimization/components/Outline.vue'),
                meta: {
                  index: 1,
                  text: 'Region Marking and Dimension',
                  icon: 'ArrowRightOutlined',
                  previous: 'cell-video',
                  next: 'merge-tracklets'
                }
              },
              {
                path: 'merge',
                name: 'merge-tracklets',
                component: () =>
                  import('src/components/User/CellOptimization/components/MergeTracklets.vue'),
                meta: {
                  index: 2,
                  text: 'Region Marking and Dimension',
                  icon: 'ArrowRightOutlined',
                  previous: 'cell-outline',
                  next: 'cell-results'
                }
              },
              {
                path: 'results',
                name: 'cell-results',
                component: () =>
                  import('src/components/User/CellOptimization/components/Overview.vue'),
                meta: {
                  index: 3,
                  text: 'Done with Overview',
                  icon: 'ArrowRightOutlined',
                  previous: 'merge-tracklets',
                }
              },

            ]
          }
        ]
      },

      {
        path: 'product-information',
        name: 'Product Information',
        component: () => import('src/components/User/ProductInformation/Index.vue')
      },
      {
        path: 'line-balancing',
        name: 'Line Balancing',
        component: () => import('src/components/User/LineBalancing/Index.vue'),
        children: [
          {
            path: 'lines',
            name: 'Lines',
            component: () => import('src/components/User/LineBalancing/components/LinesTable.vue'),
            meta: { title: 'Line Balancing', parent: 'Line Balancing' }
          },
          {
            path: 'line-details/:lineStudyId',
            name: 'Line Details',
            component: () => import('src/components/User/LineBalancing/components/LineDetails.vue'),
            meta: { parent: 'Line Balancing' }
          },
          {
            path: 'results/:lineStudyId',
            name: 'Results',
            component: () => import('src/components/User/LineBalancing/components/ResultsPage.vue'),
            meta: { parent: 'Line Balancing' }
          },
          {
            path: 'work-content/:lineStudyId',
            name: 'Work Content',
            component: () => import('src/components/User/LineBalancing/components/WorkContent.vue'),
            meta: { parent: 'Line Balancing' }
          },
          {
            path: 'result-work-content/:lineStudyId',
            name: 'Result Work Content',
            component: () => import('src/components/User/LineBalancing/components/WorkContent.vue'),
            meta: { parent: 'Line Balancing' }
          }
        ]
      },
      // {
      //   path: 'subscription',
      //   name: 'Subscription',
      //   component: () => import('src/components/User/Subscription/Index.vue')
      // },
      {
        path: 'work-instructions',
        name: 'Work Instructions',
        component: () => import('src/components/User/WorkInstructions/Index.vue'),
        children: [
          {
            path: 'studies',
            name: 'workStudies',
            component: () => import('src/components/User/WorkInstructions/StudyList.vue'),
            meta: { title: 'Work Studies', parent: 'Work Instructions' }
          },
          {
            path: 'study/:id',
            name: 'work-new-study',
            component: () => import('src/components/User/WorkInstructions/EditStudy.vue'),
          },
          {
              path: 'study/:id/result',
              name: 'work-study-result',
              component: () => import('src/components/User/WorkInstructions/StudyResult.vue'),
              meta: { title: 'Work Instructions Results', parent: 'Work Instructions' }
            }
        ]
      },

      {
        path: 'ergonomics',
        name: 'Ergonomics',
        component: () => import('src/components/User/Ergonomics/Index.vue'),
        children: [
          {
            path: 'assessments',
            name: 'Assessments',
            component: () =>
              import('src/components/User/Ergonomics/ErgonomicsRecords/ErgonomicsRecords.vue'),
            meta: { title: 'Ergonomics', parent: 'Ergonomics' }
          },
          {
            path: 'ergonomic-assessment',
            name: 'Ergonomic Assessment',
            component: () => import('src/components/User/Ergonomics/ErgonomicAssessment/Index.vue'),
            meta: { parent: 'Ergonomics' }
          },
          {
            path: 'ergonomic-result',
            name: 'Ergonomic Result',
            component: () => import('src/components/User/Ergonomics/AssessmentResult/Index.vue'),
            meta: { parent: 'Ergonomics' }
          }
        ]
      },
      {
        path: 'fmea',
        name: 'Quality Planning',
        component: () => import('src/components/User/FMEA/Index.vue'),
        children: [
          {
            path: 'records-list',
            name: 'FMEA Records',
            component: () => import('src/components/User/FMEA/FMEARecords/Index.vue'),
            meta: { title: 'Quality Planning', parent: 'Quality Planning' }
          },
          {
            path: 'fmea-assessment/:id',
            name: 'FMEA Assessment',
            component: () => import('src/components/User/FMEA/FMEAAssessment/Index.vue'),
            meta: { title: 'Quality Planning Assessment', parent: 'Quality Planning' }
          },
          {
            path: 'fmea-result/:id',
            name: 'Result Assessment',
            component: () => import('src/components/User/FMEA/AssessmentResult/Index.vue'),
            meta: { title: 'Quality Planning Assessment', parent: 'Quality Planning' }
          }
        ]
      },
      {
        path: 'pmts',
        name: 'PMTS',
        component: () => import('src/components/User/PMTS/Index.vue'),
        children: [
          {
            path: 'studies',
            name: 'pmts-studies',
            component: () => import('src/components/User/PMTS/StudyList/StudyList.vue'),
            meta: { title: 'Predetermined Motion Time System', parent: 'PMTS' }
          },
          {
            path: 'study/:id',
            name: 'pmts-study-edit',
            components: {
              modal: () => import('src/components/User/PMTS/EditStudy.vue')
            },
            beforeEnter: [keepDefaultView]
          },
          {
            path: 'study/:id/result',
            name: 'pmts-study-result',
            component: () => import('src/components/User/PMTS/StudyResult/StudyResult.vue'),
            meta: { title: 'Predetermined Motion Time System' }
          }
        ]
      }
    ]
  }
]
