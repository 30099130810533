import { defineStore } from 'pinia'
import { getIntervalsData, getJsonVariables, getLoadAnalysisChartData, getLoadAnalysisTableData, getStatus, mapIntervalsDataFromHandErgonomicsData } from 'src/utils/ergonomics'
import { useUserStore } from './user';
import { isEmpty } from 'lodash-es'
import { formatDate, dateFormat } from 'src/utils/helpers'
import { ergonomicsDummyData } from 'src/components/User/DummyData/ergonomics-data';
import ErgonomicService from 'src/services/ergonomics';

export const assessmentVariableMap = {
    //dataKey (url) , stateKey, initial value
    'reba_rula': {
        'output_video': ['output_vid_presigned_url', 'videoUrl', null],
        'intervals': ['intervals', 'intervalsList', []],
        'cycle_analysis_chart': ['cycle_analysis_chart', 'cycleAssessmentChart', {}],
        'joints_chart': ['joints_chart', 'jointsMetaData', {}],
        'angles': ['angles', 'jointsAnglesChart', {}],
        '_load_analysis_percentage': ['_load_analysis_percentage', 'loadAnalysisJson', {}],
        '_load_analysis_timestamp': ['_load_analysis_timestamp', 'loadTimestampsJson', {}],
        'RULA': ['RULA', 'maxPoolData', {}],
        'REBA': ['REBA', 'maxPoolData', {}],
    },
    'niosh': {
        'tracking_video': ['output_vid_presigned_url', 'videoUrl', null],
        'NIOSH': ['NIOSH', 'nioshJson', {}],
    },
    'snook table': {
        'tracking_video': ['output_vid_presigned_url', 'videoUrl', null],
        'SNOOK TABLE': ['SNOOK TABLE', 'snookJson', {}],
        '_load_analysis_percentage': ['_load_analysis_percentage', 'loadAnalysisJson', {}],
        '_load_analysis_timestamp': ['_load_analysis_timestamp', 'loadTimestampsJson', {}]
    },
    'hand grip & insertion force': {
        'hand_ergonomics_data': ['default', 'handErgonomicsData', []],
        'hand_ergonomics_result': ['default', 'handErgonomicsResult', []],
    },
    'hand strain index': {
        'hand_strain_index': ['default', 'handStrainData', []],
        'hand_strain_result': ['default', 'handStrainResult', []],
    },
}

export const useErgonomicStore = defineStore('ergonomics', {
    state: () => ({
        assessmentList: [],
        assessmentDataCount: 0,
        assessmentLoading: false,
        creatingAssessment: false,
        updatingAssessment: false,
        variableDataLoading: false,
        currentAssessmentObj: null,
        assessmentId: null,
        assessmentType: null,
        videoUrl: '',
        intervalsList: [],
        cycleAssessmentChart: {},
        jointsMetaData: {},
        jointsAnglesChart: {},
        maxPoolData: {},
        nioshJson: {},
        loadAnalysisJson: {},
        loadTimestampsJson: {},
        selectedInterval: null,
        assessmentInProgressList: [],
        snookJson: {},
        loadHandErgonomicsData: false,
        isUpdateHandErgonomicsData: false,
        handErgonomicsData: [],
        handErgonomicsResult: [],
        handStrainResult: [],
        handStrainData: [],
        isErgonomicWeightsAssign: [],
        handStrainId: null,
        ergonomicsDummyObject: ergonomicsDummyData,
        intervalRecommendations: {}
    }),

    getters: {
        ergonomicsList() {
            const { userIdToNameMap } = useUserStore()
            return this.assessmentList.map((record) => ({
                ...record,
                key: record.id,
                time_created: formatDate(record.time_created, dateFormat),
                in_progress: [1, 4].includes(record.process.status),
                created_by: userIdToNameMap[record.created_by] || '-'
            }))
        },
        loadAnalysisTableData() {
            if (!this.loadAnalysisJson || !Object.keys(this.loadAnalysisJson)?.length) return
            return getLoadAnalysisTableData(this.loadAnalysisJson)
        },
        loadAnalysisChartData() {
            return getLoadAnalysisChartData(this.loadTimestampsJson)
        }
    },

    actions: {
        insertDummyData(type) {
            this.assessmentList = this.assessmentList.filter(data => data.id !== 0)
            this.assessmentList.unshift(this.ergonomicsDummyObject[type].dummyData)
        },

        removeDummyData() {
            this.assessmentList = this.assessmentList.filter(data => data.id !== 0)
        },

        async getErgonomicAssessmentList(params) {
            params = { ...params, in_progress: 0 }
            const [error, listData] = await ErgonomicService.getErgAssessmentList(params)
            if (error) {
                console.log('err ->', error)
                return
            }
            this.assessmentDataCount = listData.count
            this.assessmentList = [...listData.results].map((obj) => ({ ...obj, status: getStatus(obj.process.status) }))
        },

        async getAssessmentInProgress() {
            const params = { in_progress: 1 }
            const [error, listData] = await ErgonomicService.getErgAssessmentList(params)
            if (error) return
            this.assessmentInProgressList = [...listData]
        },

        async addNewAssessment(payload) {
            this.creatingAssessment = true
            const response = await ErgonomicService.createErgonomicRecord(payload)
            this.creatingAssessment = false
            return response
        },

        async deleteErgonomicRecord(id) {
            const [err] = await ErgonomicService.deleteErgonomicRecord(id)
            return err
        },

        async getErgonomicsRecord(id) {
            id = Number(id)
            const [err, data] = await ErgonomicService.getErgonomicRecord(id)
            if (err) return
            this.currentAssessmentObj = { ...data }
            this.assessmentId = data.id
            this.assessmentType = data.assessment_type.toLowerCase()
        },

        async updateErgonomicRecord(payload) {
            const id = this.currentAssessmentObj?.id
            this.updatingAssessment = true
            const [err] = await ErgonomicService.updateErgonomicRecord(id, payload)
            this.updatingAssessment = false
            if (err) return
            this.currentAssessmentObj = { ...this.currentAssessmentObj, ...payload }
        },

        async setHandErgonomicsData(strainId) {
            this.loadHandErgonomicsData = true
            let [err, respData] = await ErgonomicService.getHandErgonomicsData(strainId)
            if (err) {
                console.error('error in fetching hand ergonomics data ->', err)
                return
            }
            this.handErgonomicsData = respData.data.map((data, i) => ({ key: i, id: data.id, name: `(${data.segment_start} - ${data.segment_end})`, thumbnail: "", arm: data.handedness || "-", frequency: data.frequency, actual_load: data.actual_load > 0 ? data.actual_load : "", grip: data.grip, wrist: data.wrist, load: (data.load || "") }))
            this.loadHandErgonomicsData = false
        },

        async setCurrentAssessment(id, key, variables) {
            id = Number(id)
            this.assessmentLoading = true
            await Promise.all(
                variables.map(async (param) => {
                    const [dataKey, stateKey, initialValue] = assessmentVariableMap[key][param];
                    const [err, responseData] = await ErgonomicService.getErgonomicAssessmentData(id, param);
                    if (err) {
                        this[stateKey] = initialValue
                        return initialValue;
                    }
                    this[stateKey] = dataKey == 'default' ? responseData : responseData[dataKey] ? responseData[dataKey] : initialValue
                    return responseData;
                })
            );
            this.assessmentLoading = false
            if (key == 'hand grip & insertion force') {
                let handStrainId = this.handErgonomicsData.id
                if (this.handErgonomicsData?.data) {
                    this.handErgonomicsData = this.handErgonomicsData.data.map((data) => ({ ...data, actual_load: data.actual_load > 0 ? data.actual_load : "" }))
                }
                if (handStrainId) this.handStrainId = handStrainId
                this.intervalsList = mapIntervalsDataFromHandErgonomicsData(this.handErgonomicsData)
                this.isErgonomicWeightsAssign = this.handErgonomicsData.every((data) => (data.actual_load && data.actual_load > 0))
            } else this.intervalsList = getIntervalsData(this.intervalsList)
            if (key == 'hand strain index') {
                this.videoUrl = this.handStrainData?.video_url
            }
        },

        async getAssessmentVariableData(id, variable) {
            const jsonVariables = getJsonVariables(this.assessmentType)
            if (isEmpty(jsonVariables)) return this.toast.error('Invalid assessment type')
            const { key } = jsonVariables;
            this.variableDataLoading = true
            const [err, data] = await ErgonomicService.getErgonomicAssessmentData(id, variable)
            this.variableDataLoading = false
            if (err) return
            const [dataKey, stateKey] = assessmentVariableMap[key][variable];
            this[stateKey] = variable === 'intervals' ? getIntervalsData(data[dataKey]) : data[dataKey];
        },

        setSelectedInterval(key) {
            this.selectedInterval = this.intervalsList?.find((interval) => interval.key == key)
        },

        async addNewInterval(payload) {
            const id = this.assessmentId
            const [err] = await ErgonomicService.addHighRiskIntervals(id, payload)
            if (err) return 0
            this.getAssessmentVariableData(id, 'intervals')
            return 1
        },

        async addNewHandErgonomicsInstance(payload) {
            const [err, data] = await ErgonomicService.addHandErgonomicsInstance(this.handStrainId, payload)
            if (err) return
            let records = data.map(record => ({ ...record, actual_load: record.actual_load > 0 ? record.actual_load : "" }))
            this.handErgonomicsData = [...this.handErgonomicsData, ...records]
            this.intervalsList = mapIntervalsDataFromHandErgonomicsData(this.handErgonomicsData)
            this.isErgonomicWeightsAssign = this.handErgonomicsData.every((data) => (data.actual_load && data.actual_load > 0))
            return
        },

        async deleteHandErgonomicsInstance(assessmentId) {
            const [err] = await ErgonomicService.deleteHandErgonomicsInstance(assessmentId)
            if (err) return
            this.handErgonomicsData = this.handErgonomicsData.filter(data => data.id !== assessmentId)
            this.intervalsList = mapIntervalsDataFromHandErgonomicsData(this.handErgonomicsData)
            this.isErgonomicWeightsAssign = this.handErgonomicsData.every((data) => (data.actual_load && data.actual_load > 0))
            return
        },

        async deleteHandErgonomicsInterval(segmentKey) {
            let resp = this.handErgonomicsData[segmentKey]
            const [err] = await ErgonomicService.deleteHandErgonomicsInstance(resp.id)
            if (err) return
            this.handErgonomicsData = this.handErgonomicsData.filter(data => data.id !== resp.id)
            this.intervalsList = mapIntervalsDataFromHandErgonomicsData(this.handErgonomicsData)
            this.isErgonomicWeightsAssign = this.handErgonomicsData.every((data) => (data.actual_load && data.actual_load > 0))
            return
        },

        async deleteInterval(key) {
            const id = this.assessmentId
            const [err] = await ErgonomicService.deleteInterval(id, key)
            if (err) return 0
            return 1
        },

        async updateInterval(key, payload) {
            const params = { id: this.assessmentId, key: key }
            const [err] = await ErgonomicService.updateIntervalScores(params, payload)
            if (err) return 0
            const temp = [...this.intervalsList]
            const index = this.intervalsList.findIndex((iv) => iv.key === key)
            temp[index] = { ...temp[index], ...payload['RebaRula_data'] }
            this.intervalsList = temp
            return 1
        },

        async updateNioshData(params, payload) {
            const [err] = await ErgonomicService.nioshInputsUpdate(params, payload)
            if (err) return 0
            return 1
        },

        async updateHandStrainIndexData(params, payload) {
            const [err] = await ErgonomicService.handStrainIndexUpdate(params, payload)
            if (err) return 0
            return 1
        },

        async updateSnookData(params, payload) {
            const [err] = await ErgonomicService.snookInputUpdate(params, payload)
            if (err) return 0
            return 1
        },

        async validateSnookNioshAssessmentName(body) {
            const [err, data] = await ErgonomicService.validateSnookNioshAssessmentName(body)
            if (err) return 0
            return data.Unique
        },

        async updateSnookNoteData(id, payload) {
            const [err] = await ErgonomicService.snookNoteInputUpdate(id, payload)
            if (err) return 0
            this.snookJson = { ...this.snookJson, 0: { ...this.snookJson[0], ...payload } }
            return 1
        },

        async updateHandErgonomics(id, payload) {
            this.isUpdateHandErgonomicsData = true
            let [err] = await ErgonomicService.handErgonomicsInputUpdate(id, payload)
            this.isUpdateHandErgonomicsData = false
            if (err) return 0
            this.handErgonomicsData = this.handErgonomicsData.map(data => {
                if (data.id == id) {
                    return ({
                        ...data,
                        ...payload
                    })
                }
                return data
            })
            this.isErgonomicWeightsAssign = this.handErgonomicsData.every((data) => (data.actual_load && data.actual_load > 0))
            return 1
        },

        resetErgonomicState() {
            this.currentAssessmentObj = null
            this.assessmentId = null
            this.assessmentType = null
            Object.values(assessmentVariableMap).forEach((values) => {
                const dataKeys = Object.values(values)
                dataKeys.forEach(([, stateKey, initValue]) => this[stateKey] = initValue)
            })
        },

        async fetchRecommendations() {
            const [err, data] = await ErgonomicService.getRecommendation(this.currentAssessmentObj?.id)
            if (err) {
                this.intervalRecommendations = {}
                return
            }
            this.intervalRecommendations = data
        }
    }
})

