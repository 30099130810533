import ApiHelper from './index'

const startOperatorTracking = async (studyId) => {
  return await ApiHelper.post(`deep_model/person_tracking/${studyId}`)
}

const getOperatorTrackingData = async (studyId) => {
  return await ApiHelper.get(`hand_tracking/get_person_tracking_result/${studyId}`)
}

const getCellOperatorTrackingResults = async (studyId)=>{
  return await ApiHelper.get(`cell_layout/get_person_tracking_result/${studyId}`)
}

const getOperatorTracklets = async (studyId) => {
  return await ApiHelper.get(`cell_layout/get_merge_tracklet_result/${studyId}`)
}

const mergeOperatorTracklets = async (studyId, payload) => {
  return await ApiHelper.post(`cell_layout/merge_tracklets/${studyId}`, payload)
}

export default {
  startOperatorTracking,
  getOperatorTrackingData,
  getCellOperatorTrackingResults,
  mergeOperatorTracklets,
  getOperatorTracklets
}
