import { defineStore } from 'pinia'
import { valueAssessment } from 'src/components/User/StationBalancing/config'
import SegmentationService from 'src/services/segmentation'
import { useStationStore } from 'src/stores/station'
import {
  findMode,
  flattenSegmentSuggestionandAnswers,
  getAssessmentBasedOnPrecedence,
  getCyclesDurations,
  getCycleSecondShortestId,
  getMeanAndStandardDeviation,
  getMeanOfStepCyclesTime,
  getMotionEconomy,
  getStepCyclesTime,
  getStepValueType,
  segmentIdToSuggestions
} from 'src/utils/helpers'
import { useClassificationStore } from './classification'
import { isEqual, round, sumBy, sum, } from 'lodash-es'

export const useSegmentationStore = defineStore('segmentation', {
  state: () => ({
    allSegments: [], // store all steps
    segmentationData: [], // store steps after filtering deleted steps
    isSegmentationRunning: false,
    segmentsAvgTimeObject: {}
  }),

  getters: {
    masterCycleSegments() {
      if (!this.segmentationData.length) return []
      return this.segmentationData.filter((step) => step.work_cycle.is_master === true)
    },

    stepToCyclesSegmentation() {
      if (!this.segmentationData.length) return {}
      const { cyclesIdToObjMap } = useStationStore()
      const stepToCycleMap = this.segmentationData.reduce((map, step) => {
        const { step_index } = step
        const cycleIndex = cyclesIdToObjMap[step.work_cycle.id]?.cycle_index
        if (map[step_index]) {
          if (step.work_cycle.is_master) {
            map[step_index].unshift({ ...step, cycleIndex })
          } else {
            map[step_index].push({ ...step, cycleIndex })
          }
        } else {
          map[step_index] = [{ ...step, cycleIndex }]
        }
        return map
      }, {})
      return stepToCycleMap
    },

    cycleToSegmentsMap(state) {
      if (!state.segmentationData?.length) return {}
      const { cyclesIdToObjMap } = useStationStore()
      const cycleIds = Object.keys(cyclesIdToObjMap)
      const cycleToStepMap = cycleIds.reduce((map, cycleId) => {
        map[cycleId] = state.segmentationData.filter((s) => s.work_cycle.id == cycleId).sort((a, b) => a.step_index - b.step_index)
        return map
      }, {})
      return cycleToStepMap
    },

    cyclesNotSegmented() {
      const { cyclesIdToObjMap, workCycles } = useStationStore()
      if (!Object.keys(this.stepToCyclesSegmentation).length || !workCycles.length) return {}
      const stepCycles = this.stepToCyclesSegmentation[0]
      const stepCyclesIds = stepCycles.map((s) => s.work_cycle.id)
      const allWorkCycleIds = Object.keys(cyclesIdToObjMap).map((id) => Number(id))
      const unsegmentsCycles = allWorkCycleIds.filter((id) => !stepCyclesIds.includes(id))
      return {
        notSegmentedCycles: unsegmentsCycles,
        notSegmentedVideos: unsegmentsCycles.map(id => cyclesIdToObjMap[id].file)
      }
    },

    stepsDataForEditTimings(state) {
      const { masterCycle } = useStationStore()
      if (!masterCycle || !Object.keys(masterCycle)?.length || !this.segmentationData?.length) return []
      return state.segmentationData
        ?.filter((s) => s.work_cycle.id === masterCycle.id)
        .sort((a, b) => a.step_index - b.step_index)
    },

    segmentationDatForSuggestions() {
      const { idToActionName } = useClassificationStore()
      const suggestionsDict = segmentIdToSuggestions()

      if (!this.stepToCyclesSegmentation || !Object.keys(this.stepToCyclesSegmentation)?.length)
        return []

      const suggestionData = this.masterCycleSegments?.map((segment) => {
        const fps = segment.videoFPS
        const actions = segment.step_action_association.map((el) => {
          return idToActionName[el.action]
        })

        const { segment_start, segment_end } = segment
        const segmentDuration = Number(
          ((segment_end - segment_start) / fps).toFixed(2)
        )
        const cycle_seg = this.stepToCyclesSegmentation[segment.step_index]
        const segCycleTime = getStepCyclesTime(cycle_seg)
        const { mean, std } = getMeanAndStandardDeviation(segCycleTime)

        return {
          ...segment,
          actions,
          assessment: getStepValueType(segment),
          segmentDuration,
          avg_time: mean,
          step_std: std,
          actual_step_time: `${mean} ± ${std.toFixed(2)} (s)`,
          segment_start: segment.segment_start / fps,
          segment_end: segment.segment_end / fps,
          actionsSuggestions: suggestionsDict[segment.id],
          motionEconomy: getMotionEconomy(segment, segmentDuration)
        }
      })
      return suggestionData.sort((a, b) => a.step_index - b.step_index)
    },

    stationLayoutSuggestion() {
      const { workCycles } = useStationStore()

      if (!this.segmentationDatForSuggestions.length) return {}
      const totalCycleTime = this.segmentationDatForSuggestions.reduce((res, el) => res += el.segmentDuration, 0)
      const valueAddSegments = this.segmentationDatForSuggestions.filter(
        ({ assessment }) => assessment === valueAssessment.VA
      )

      const totalValueAddTimeInSec = valueAddSegments
        .map((el) => el.segment_end - el.segment_start)
        .reduce((res, el) => res + el, 0)


      // const totalCycleTime = this.segmentationDatForSuggestions.at(-1)?.segment_end || 0
      const cyclesDurations = workCycles.length ? getCyclesDurations(workCycles) : 0
      const { mean, std } = getMeanAndStandardDeviation(cyclesDurations)
      const stats = {
        totalCycleTime: totalCycleTime.toFixed(2) + ' (s)',
        totalValueAddTime: totalValueAddTimeInSec.toFixed(2) + ' (s)',
        valueAddPercentage: ((totalValueAddTimeInSec / totalCycleTime) * 100).toFixed(1) + '%',
        medianOfCycleTimes: mean,
        cycles_std: std,
        actual_cycle_time: `${Math.round(mean)} ± ${std.toFixed(2)} (s)`,
        no_of_steps: this.masterCycleSegments.length
      }
      return stats
    },

    valueAddedPieChart() {
      if (!this.segmentationData?.length) return []

      const init = {
        [valueAssessment.VA]: 0,
        [valueAssessment.NVA]: 0,
        [valueAssessment.NNVA]: 0,
      }

      const data = this.masterCycleSegments?.reduce((res, el) => {
        const { segment_start, segment_end, value_type_by_user,
          value_type } = el
        const valueTypes = value_type_by_user ? [value_type] : el.step_action_association.map((el) => el.value_type)
        const assessment = valueTypes.length ? getAssessmentBasedOnPrecedence(valueTypes) : valueAssessment.VA
        res[assessment] += (segment_end - segment_start) / el.videoFPS
        return res
      }, init)

      const sum = Object.values(data).reduce((res, el) => (res += el), 0)
      const valueTypeData = Object.entries(data).map(([vt, value]) => [vt, (value / sum) * 100])
      return valueTypeData.filter(([, value]) => value !== 0)
    },

    stepsTimePerCycle() {
      const sorted = this.masterCycleSegments.sort((a, b) => b.step_index - a.step_index)

      const data = sorted.map((segment) => {
        const { name, step_index, seg_time } = segment
        return {
          name: name,
          data: [seg_time],
          step_index
        }
      })
      return data
    },

    totalCycleTime() {
      if (!this.masterCycleSegments?.length) return
      const cycleTime = this.masterCycleSegments.reduce((res, el) => {
        const segmentDuration = Number(
          ((el.segment_end - el.segment_start) / el.videoFPS)?.toFixed(2)
        )
        res += segmentDuration
        return res
      }, 0)
      return cycleTime
    },

    isStepsNameNotAssigned() {
      if (!this.segmentationData?.length) return false
      const steps = this.segmentationData
        ?.filter((segment) => segment.work_cycle.is_master === true)
        ?.sort((a, b) => a.step_index - b.step_index)

      const isNull = steps.some((s) => s.name === null || s.name === '')
      return isNull
    },

    stepsTableData() {
      if (!this.masterCycleSegments.length || isEqual(this.stepToCyclesSegmentation)) []
      let { workCycles } = useStationStore()
      const masterCycleSteps = this.masterCycleSegments.length
      workCycles = workCycles.filter((c) => this.cycleToSegmentsMap[c.id]?.length === masterCycleSteps)
      const secondShortestId = getCycleSecondShortestId(workCycles)
      const stepsData = this.masterCycleSegments.map((segment) => {
        const stepInCycles = this.stepToCyclesSegmentation[segment.step_index] || []
        let stepTimes = stepInCycles.map(step => step.seg_time)
        const { mean, std } = getMeanAndStandardDeviation(stepInCycles)
        const obj = {
          name: segment.name,
          step_index: segment.step_index,
          mean: mean,
          step_std: `± ${Math.round(std * 100) / 100}`,
          mode: findMode(stepTimes) || '-',
          second_shortest: stepInCycles.find((s) => s.work_cycle.id == secondShortestId)?.seg_time || 0,
          stepCyclesTime: stepTimes,
          observedTime: sum(stepTimes),
          assessment: getStepValueType(segment)
        }
        stepInCycles.forEach((step) => {
          obj[`step_time_${step.work_cycle.id}`] = step.seg_time
        })
        return obj
      })
      return stepsData.sort((a, b) => a.step_index - b.step_index)
    },

    cyclesTableData() {
      if (!this.stepsTableData?.length) return []
      const { workCycles } = useStationStore()
      const cyclesTime = workCycles.reduce((res, cycle) => {
        res[`cycle_time_${cycle.id}`] = Math.round(sumBy(this.stepsTableData, `step_time_${cycle.id}`) * 100) / 100
        return res
      }, {})
      const timeValues = Object.values(cyclesTime)
      const { mean, std } = getMeanAndStandardDeviation(timeValues.map((time) => ({ seg_time: time })))
      return {
        ...cyclesTime,
        mean: mean,
        observedTime: round(sum(timeValues), 2),
        cycle_std: '± ' + Math.round(std * 100) / 100,
        mode: findMode(timeValues) || '-',
        second_shortest: round(sumBy(this.stepsTableData, 'second_shortest'), 2)
      }
    }
  },

  actions: {
    setIsSegmentationRunning(value) {
      this.isSegmentationRunning = value
    },

    async createSegments(cycleId, updatedSegments) {
      const { newStudyId } = useStationStore()
      const [error] = await SegmentationService.createSegments(
        newStudyId,
        cycleId,
        updatedSegments
      )
      if (error) {
        console.log('error:', error)
        return 0
      }
      return 1
    },

    async fetchAllSteps() {
      const { newStudy, numberOfSteps } = useStationStore()
      if (!numberOfSteps) return
      const [error, data] = await SegmentationService.getSegments(newStudy.id)
      if (error) {
        console.log('error:', error)
        return
      }
      this.allSegments = [...data].map(flattenSegmentSuggestionandAnswers)
      const flattenSegments = data.filter(step => step.segment_end - step.segment_start >= 1)?.map(flattenSegmentSuggestionandAnswers)
      this.segmentationData = flattenSegments.sort((a, b) => a.step_index - b.step_index)
    },

    async getEstimatedNoOfSteps() {
      const { newStudyId } = useStationStore()
      const [error, data] = await SegmentationService.getEstimatedNoOfSteps(newStudyId)
      if (error) {
        console.log('error in getting estimated steps')
        return 10
      }
      return data.estimated_steps
    },

    async deleteSegment(segmentId) {
      const [error] = await SegmentationService.deleteSegment(segmentId)
      if (error) {
        console.log('error:', error)
        return
      }
    },

    updateSegmentInList(id, obj) {
      const temp = [...this.segmentationData]
      const index = temp.findIndex((segment) => segment.id === id)
      temp[index] = { ...temp[index], ...obj }
      this.segmentationData = temp
    },

    async getStepsAverageTimeObj() {
      const { newStudyId } = useStationStore()
      const [error, data] = await SegmentationService.getStepsAverageTime(newStudyId)
      if (error) {
        console.log('error: ', error)
        return
      }
      // this.segmentsAvgTimeObject = data
    },

    async getCycleSegments(cycle_no) {
      const { newStudyId: studyId, masterCycle } = useStationStore()
      const [err, data] = await SegmentationService.getCycleSegments(studyId, cycle_no)
      if (err) {
        return
      }
      // if (cycle_no === masterCycle?.cycle_no) {
      //   this.masterCycleSegments = data
      //     .map(flattenSegmentSuggestionandAnswers)
      //     .sort((a, b) => a.step_index - b.step_index)
      // }
    },

    async startSegmentation(numOfSegments) {
      console.log('segemntation req')
      // return
      const { newStudyId: studyId } = useStationStore()
      const [error] = await SegmentationService.startSegmentation(studyId, numOfSegments)
      if (error) {
        console.log('error:', error)
      }
    },

    async updateSegment(id, payload) {
      // const { id, name } = payload
      const [error] = await SegmentationService.updateSegment(id, { ...payload })
      if (error) {
        console.log('error', error)
        return 0
      }
      return 1
      // this.updateSegmentInList(id, { ...payload })
    },

    async createSegmentActionAssociation(payload) {
      const { step } = payload
      const [error, data] = await SegmentationService.createStepActionAssociation(payload)
      if (error) {
        console.log('error:', error)
        return
      }
      data['answers'] = []

      const temp = [...this.segmentationData]
      const index = temp.findIndex(({ id }) => id === step)
      if (index >= 0) {
        if (!temp[index]['step_action_association']) {
          temp[index]['step_action_association'] = [];
        }
        temp[index]['step_action_association']?.push(data)
      } else temp[index]['step_action_association'] = [];
      this.segmentationData = temp
    },

    async removeSegmentActionAssociation(segmentId, actionId) {
      const params = {
        step: segmentId,
        action: actionId
      }
      const [error] = await SegmentationService.deleteStepActionAssociation(params)
      if (error) {
        console.log('error:', error)
        return
      }
      const temp = [...this.segmentationData]
      const index = temp.findIndex(({ id }) => id === segmentId)
      const { step_action_association } = temp[index]
      const updatedAssociations = step_action_association?.filter((el) => el.action !== actionId)
      temp[index]['step_action_association'] = updatedAssociations
      this.segmentationData = temp
    },

    // async updateValueType(step, valueType) {
    //   const [error] = await SegmentationService.updateValueType({ step, valueType })
    //   if (error) {
    //     console.log('error:', error)
    //     return
    //   }
    //   const temp = [...this.segmentationData]
    //   const index = temp.findIndex(({ id }) => id === step)

    //   const updatedValueType = temp[index]['step_action_association'].map((el) => ({
    //     ...el,
    //     value_type: valueType
    //   }))
    //   temp[index]['step_action_association'] = updatedValueType
    //   this.segmentationData = temp
    // },

    async addedQuestionAnswer(payload) {
      const [error, data] = await SegmentationService.stepQuestionAnswer(payload)
      if (error) {
        console.log('error:'.error)
        return
      }
      return data
    },

    resetSegmentsData() {
      this.segmentationData = []
    },

    async addNewParts(stepId, payload) {
      console.log("inside part view")
      const [error, data] = await SegmentationService.addStepNewPart(stepId, { ...payload })
      if (error) {
        console.log('error', error)
        return 0
      }
      return data
    },

    async addNewTools(stepId, payload) {
      console.log("inside part view")
      const [error, data] = await SegmentationService.addStepNewTool(stepId, { ...payload })
      if (error) {
        console.log('error', error)
        return 0
      }
      return data
    },

    async removeStepTool(toolId) {
      const [error] = await SegmentationService.deleteStepTool(toolId)
      if (error) {
        console.log('error:', error)
        return 0
      }
      return 1
    },

    async removeStepPart(partId) {
      const [error] = await SegmentationService.deleteStepPart(partId)
      if (error) {
        console.log('error:', error)
        return 0
      }
      return 1
    },
  }
})
