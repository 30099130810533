export const CDN_URL = import.meta.env.VITE_CDN_URL || ''
export const THUMBNAIL_CDN_URL   = import.meta.env.VITE_THUMBNAIL_CDN_URL || ''

export const ASSETS = {
  BACKGROUND_IMAGE: `${CDN_URL}/background.png`,
  LOGO: `${CDN_URL}path/to/your/logo.png`,
  ICON: `${CDN_URL}path/to/your/icon.png`,
  PMTS: {
    MODAPTS: {
      M1: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_01_Calculator.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_02_Trigger.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_03_TurnDownNut.png`
      },
      M2: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_04_TurnKnob.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_05_TurnOver.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_06_WalkGrab.png`
      },
      M3: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_07_MovePencil.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_08_Move_Divider.png`
      },
      M4: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_09_Move_wo_body.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_10_Move_w_body.png`
      },
      M5: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_11_To_L_R.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_12_Up.png`
      },
      M7: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_13_M7_Move.png`
      },
      G0: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_14_TouchButton.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_15_Hand_on_Box.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_16_Palm_on_Paper.png`
      },
      G1: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_17_Fingers_Marble.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_18_Fingers_wrench.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_19_Drawer_Pull.png`
      },
      G3: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_20_Get_Small_Obj.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_21_Get_Flat_Obj.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_22_Get_Jumbled.png`
      },
      P0: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_23_Toss_into_Box.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_24_Return Tool.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_25_TransferBrush.png`
      },
      P2: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_26_Locate Pencil.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_27_Register Philips.png`
      },
      P5: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_28_Register_Turn.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_29_Thread_Nut.png`
      },
      J2: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_30_Roll_Nut.png`
      },
      X4: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_31_Extra_Force.png`
      },
      W5: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_32_Walk_Restricted.png`
      },
      F3: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_33_Foot_Pedal.png`
      },
      B17: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_34_Bend_Arise.png`
      },
      S30: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_35_Sitting_Chair.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_36_PushBack_Chair.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_37_Rising_Chair.png`,
        4: `${CDN_URL}/PMTS/MODAPTS/MOD_38_Pulling_Chair.png`
      },
      C3: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_39_Turn_Crank.png`
      },
      USE: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_40_Hammer.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_41_Brush_Debris.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_42_Not_Drill.png`
      },
      E2: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_43_Eye Fixation.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_44_Eye Travel.png`
      },
      E4: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_45_Eye_Focus.png`
      },
      HAND: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_46_Write.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_47_PRINT.png`
      },
      LOAD: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_48_Load_1_Hand.png`,
        2: `${CDN_URL}/PMTS/MODAPTS/MOD_49_Load_2_Hands.png`,
        3: `${CDN_URL}/PMTS/MODAPTS/MOD_50_slide.png`
      },
      DECIDE: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_51_D3_Decision.png`
      },
      COUNT: {
        1: `${CDN_URL}/PMTS/MODAPTS/MOD_52_N3_Counting.png`
      }
    }
  },
  ERGONOMICS: {
    NIOSH: {
      variables: `${CDN_URL}/Ergonomics/Niosh/niosh_variables.png`,
      horizontalLocation: `${CDN_URL}/Ergonomics/Niosh/horizontal_location.png`,
      verticalLocation: `${CDN_URL}/Ergonomics/Niosh/vertical_location.png`,
      angleOfAsymmetry: `${CDN_URL}/Ergonomics/Niosh/asymmetry_angle.png`
    }
  },
  REBARULA: {
    REBA: `${CDN_URL}/Ergonomics/Reba_Rula/REBA_MSD_RISK.png`,
    RULA: `${CDN_URL}/Ergonomics/Reba_Rula/RULA_MSD_RISK.png`
  },
  FUNDAMENTALS: {
    VIDEO_URL: {
      KAIZEN_INTRO: `${CDN_URL}/Fundamentals/1_What_is_Kaizen.mp4`,
      WORK: `${CDN_URL}/Fundamentals/2_WHAT_is_WORK.mp4`,
      CAT_OF_WORK: `${CDN_URL}/Fundamentals/3_CAT_OF_WORK.mp4`,
      TIMWOODS: `${CDN_URL}/Fundamentals/4_TIMWOODS.mp4`,
      WASTE: `${CDN_URL}/Fundamentals/5_How_to_Identify_the_Waste.mp4`,
      KAIZEN_IMPLEMENTATION: `${CDN_URL}/Fundamentals/6_521h_ecssra.mp4`,
      THERBLIGS: `${CDN_URL}/Fundamentals/7_Therbligs.mp4`,
      MICRO_MOTION: `${CDN_URL}/Fundamentals/8_Micromotion.mp4`,
      MOST: `${CDN_URL}/Fundamentals/9_MOST.mp4`,
      PTMS: `${CDN_URL}/Fundamentals/10_PTMS.mp4`,
      MACROMOTION_ASSEMBLY_LINE: `${CDN_URL}/Fundamentals/11_MACROMOTION_ASSY_LINE.mp4`,
      BOM: `${CDN_URL}/Fundamentals/12_BILL_OF_MATERIALS.mp4`,
      STANDARDIZE_WORK: `${CDN_URL}/Fundamentals/13_STANDARDIZE_WORK.mp4`,
      STANDARD_WORK: `${CDN_URL}/Fundamentals/14_WHY_TO_FOLLOW_STD_WORK.mp4`,
      REACH_ZONES: `${CDN_URL}/Fundamentals/15_SD_WORK_Reach_Zone.mp4`,
      WORK_CELL: `${CDN_URL}/Fundamentals/16_WORK_CELL.mp4`,
      PRACTICAL_KAIZEN: `${CDN_URL}/Fundamentals/Practical_Kaizen.mp4`
    }
  },
  DOCUMENTATION: {
    VIDEO_URL: {
      KCP_OVERVIEW: `${CDN_URL}/doc-media/Kaizen Copilot Detailed Overview.mp4`,
      CAPTURE_VIDEOS:`${CDN_URL}/doc-media/How to Capture Videos for Kaizen Copilot.pdf`,
      SD_TIME_STUDIES: `${CDN_URL}/doc-media/Kaizen Copilot_ Rapid Time Study and Station Improvement.mp4`,
      LINE_BALANCING: `${CDN_URL}/doc-media/Kaizen Copilot_ Automatic Line Balance.mp4`,
      ERGO_REBA_RULA: `${CDN_URL}/doc-media/Kaizen Copilot Ergonomics Module_ REBA and RULA Analyses.mp4`,
      ERGO_NIOSH: `${CDN_URL}/doc-media/Kaizen Copilot Ergonomics Module_ NIOSH Lifting Guidelines.mp4`,
      ERGO_SNOOK: `${CDN_URL}/doc-media/Kaizen Copilot Ergonomics Module_ Snook Tables.mp4`,
      ERGO_HAND_GRIP: `${CDN_URL}/doc-media/Kaizen Copilot Ergonomics Module_ Hand Grip & Insertion Force.mp4`,
      FMEA: `${CDN_URL}/doc-media/Kaizen Copilot_ FMEA Analysis Tutorial.mp4`,
      PMTS_MODAPTS: `${CDN_URL}/doc-media/MODAPTS Demo Video.mp4`,
      ERGO_CASE_STUDIES: `${CDN_URL}/doc-media/kaizen_copilot_at_ergo_cup (1080p).mp4`
    }
  },
  TOUR: {
    ERGONOMICS: {
      THUMBNAIL: `${THUMBNAIL_CDN_URL}/tour-media/Trunk(1).jpg`,
      SNOOK: {
        OUTPUT_VIDEO: `${CDN_URL}/tour-media/Pulling.mp4`,
        THUMBNAIL: `${THUMBNAIL_CDN_URL}/tour-media/Pulling (1).jpg`,
        VIDEO_URL: `${CDN_URL}/tour-media/ergonomics/processed/Pulling_880/Pulling_880_tracking.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYWFGI2RLB7ZLUFG6%2F20240318%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240318T072208Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=6d7cdfcb1f1c2b658f1753ee9cf2594b60c43bd90860086c401f17eee2ce495b`
      },
      NIOSH: {
        OUTPUT_VIDEO: `${CDN_URL}/tour-media/WH+Lifting+%26+Lowering.mp4`,
        THUMBNAIL: `${THUMBNAIL_CDN_URL}/tour-media/Warehouse Lifting operation.jpg`
      },
      HAND_STRAIN: {
        VIDEO_URL: `${CDN_URL}/tour-media/ergonomics/strain_index/12/output_video.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYWFGI2RLGB6WANUX%2F20241119%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241119T052709Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=92f7ef77a91debb13e73b364a34843fe38b08b58dd8952b7f52860165fe2414e`
      },
      HAND_GRIP: {
        OUTPUT_VIDEO: `${CDN_URL}/tour-media/Filter+assembly+slower+-+Trim.mp4`,
        THUMBNAIL: `${THUMBNAIL_CDN_URL}/tour-media/Filter assembly slower - Trim.jpg`,
        VIDEO_URL: `${CDN_URL}/tour-media/Filter+assembly+slower+-+Trim.mp4`,
        MAP_HAND_ERGONOMICS_DATA: {
          THUMBNAIL: {
            1: `${CDN_URL}/tour-media/343.jpg`,
            2: `${CDN_URL}/tour-media/3018.jpg`
          }
        },
        ANALYZED_DATA: {
          THUMBNAIL: {
            1: `${CDN_URL}/tour-media/343.jpg`,
            2: `${CDN_URL}/tour-media/3018.jpg`
          }
        }
      },
      RULA: {
        OUTPUT_VIDEO: `${CDN_URL}/tour-media/IMG_8133+(1).mov`,
        INTERVAL_VIDEO: {
            1: `${CDN_URL}/tour-media/frame_482.jpg`,
            2: `${CDN_URL}/tour-media/frame_2169.jpg`
        },
      }
    },
    STATION_DESIGN:{
      URL:`${CDN_URL}/tour-media/Spring+assembly.mkv`,
      THUMBNAIL:`${THUMBNAIL_CDN_URL}/tour-media/UShape6.jpg`,
      MOTION_ECONOMY_IMAGE:`${CDN_URL}/tour-media/station_analysis/183/hand_tracking/step_0_right.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYWFGI2RLB7ZLUFG6%2F20240314%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240314T062304Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=5ac740c4bffcda24164f45136bca8b49b64d59d0774d3a1f49558d8ffc6be50d`,
      STUDY:{
        FILE:{
          THUMBNAIL:`${THUMBNAIL_CDN_URL}/tour-media/Netgear Assembly 2.jpg`
        }
      },
      ERGONOMICS_RESULT_IMAGE:`${CDN_URL}/tour-media/image_0.jpg`,
      STEPS:{
        MOTION_ECONOMY_IMAGE:{
          1:`${CDN_URL}/tour-media/station_analysis/183/hand_tracking/step_0_right.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYWFGI2RLB7ZLUFG6%2F20240314%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240314T073503Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=bf70638bc5963dc68361876ec887cdb4a2c57a4603166eb94e4ea4bc86e9fb83`,
          2:`${CDN_URL}/tour-media/station_analysis/183/hand_tracking/step_1_right.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYWFGI2RLB7ZLUFG6%2F20240314%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240314T073503Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=a27823621ddc71fd7f0dda314a94fb2c8d7bb8aab96b9e9db1a4a98e8e3ded16`,
          3:`${CDN_URL}/tour-media/station_analysis/183/hand_tracking/step_2_right.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYWFGI2RLB7ZLUFG6%2F20240314%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240314T073503Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=4a62d0661b09dec751b6362682cd87039e42ef67939d83f02c3871a93deb3d17`,
          4:`${CDN_URL}/tour-media/station_analysis/183/hand_tracking/step_3_right.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYWFGI2RLB7ZLUFG6%2F20240314%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240314T073503Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=e54095b0cf77961fa35edaf7debffc0efd6c0bc3846021bbe91f7bdac6768eb6`,
          5:`${CDN_URL}/tour-media/station_analysis/183/hand_tracking/step_4_right.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYWFGI2RLB7ZLUFG6%2F20240314%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240314T073503Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=b030d61f2993a10fb9c1e1555f2a9e59c9d89d29817b9c40d39c3e1250296683`,
          6:`${CDN_URL}/tour-media/station_analysis/183/hand_tracking/step_5_right.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYWFGI2RLB7ZLUFG6%2F20240314%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240314T073503Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=485dbfccec59d3546957ebdf23210e36ea3d2388e69ec8e05f1b087723ea7ee7`,
          7:`${CDN_URL}/tour-media/station_analysis/183/hand_tracking/step_6_right.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYWFGI2RLB7ZLUFG6%2F20240314%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240314T073503Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=adfe3fdaa8849a1fbf453a1b38d40d203c5beec60399fefa7e7da4aff4efb7d5`,
          8:`${CDN_URL}/tour-media/station_analysis/183/hand_tracking/step_7_left.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYWFGI2RLB7ZLUFG6%2F20240314%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240314T073503Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=a5e31a6e5dd3d70f333dc1f0c0fe93fdbfbe12ae1ee1e624e8c2f1353f8c9716`,
        }
      }
    },
    FILE_MANAGEMENT:{
      VIDEO_URL:`${CDN_URL}/tour-media/clamp-lamp-assembly__2020-12-1714-52-10.mp4`,
      THUMBNAIL:`${THUMBNAIL_CDN_URL}/tour-media/clamp-lamp-assembly__2020-12-1714-52-10.jpg`,
    },
    PMTS:{
      VIDEO_URL:`${CDN_URL}/tour-media/Spring+assembly.mkv`,
    }
  }
}
