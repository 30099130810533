import { useToast } from 'vue-toastification'
import { isEmpty } from '@firebase/util'

const toast = useToast()
export const defaultError = 'Something went wrong, please try again!'

export const handleError = (error) => {
  if (isEmpty(error)) return
  if (error?.status === 400) {
    const [errorMessage] = Object.values(error.data)[0]
    if (!errorMessage || typeof errorMessage !== 'string') return
    toast.error(errorMessage || defaultError, {
      timeout: 3000
    })
  }
  if (error?.status === 429) {
    const [errorMessage] = Object.values(error.data)
    if (!errorMessage || typeof errorMessage !== 'string') return
    toast.error(errorMessage || defaultError, {
      timeout: 3000
    })
  }
}

export const customErrorHandler = (error) => {
  if (isEmpty(error)) return
  let [errorMessage] = Object.values(error.data)
  if (!errorMessage || typeof errorMessage !== 'string') return
  toast.error(errorMessage || defaultError, {
    timeout: 3000
  })
}

export const extractErrorMessage = (errorResponse) => {
  if (!errorResponse || typeof errorResponse !== 'object') {
    return 'An unknown error occurred.'
  }

  let messages = []
  for (const [field, errors] of Object.entries(errorResponse)) {
    if (Array.isArray(errors)) {
      messages.push(
        ...errors.map((error) => `${field !== 'non_field_errors' ? `${field}: ` : ''}${error}`)
      )
    }
  }

  return messages.join(' ')
}
