import { useUserStore } from "src/stores/user"
import { dateFormat, formatDate, } from "./helpers"
import { statusConfig } from "src/components/User/CellOptimization/config"

const getStudyStatus = (process) => {
    if (!process || !process.status?.name) return statusConfig['Pending'].key
    const processStatus = process.status?.name
    switch (processStatus) {
        case 'In Queue':
            return statusConfig['InQueue']?.key
        case 'In Progress':
            return statusConfig['InProgress']?.key
        case 'Failed':
            return statusConfig['Failed']?.key
        case 'Finished':
            return statusConfig['Finished']?.key
        default:
            return statusConfig['Pending'].key
    }
}

export const formatCellStudy = (study) => {
    const { userIdToNameMap } = useUserStore()
    let obj = {
        ...study,
        progress: study.process?.progress ? Math.floor(study.process?.progress) : 0,
        time_created: formatDate(study.time_created, dateFormat),
        created_by: userIdToNameMap[study.created_by] || '-'
    }
    obj['status'] = getStudyStatus(obj.process)
    return obj
}

export const getIndexedJsonOfRegionIds = (json, idsToIndexMap) => {
    let indexed = {}
    for (let k in json) {
        let key = JSON.parse(k)
        const x = idsToIndexMap[key[0]]
        const y = idsToIndexMap[key[1]]
        indexed[[x, y]] = json[k]
    }
    return indexed
}